@import "vars";
@import "libs";
@import "fonts";

::placeholder {
	color: #646464; }
html, body {
	height: 100%; }
.main {
	flex: 1 0 auto; }
html {
	font-size: 10px; }
body {
	font-size: 1.5rem;
	min-width: 320px;
	position: relative;
	line-height: 1.8;
	font-family: $default-font;
	overflow-x: hidden;
	color: $text;
	display: flex;
	flex-direction: column;
	input, textarea {
		display: block;
		width: 100%;
		outline: none;
		resize: none;
		border: none; }
	input, textarea, button, a {
		&:active, &:hover, &:focus {
			outline: 0;
			outline-offset: 0; } }
	button {
		cursor: pointer;
		background-color: transparent;
		border: none;
		padding: 0; } }
b, strong {
	font-weight: 700; }
p {
	margin-bottom: 0; }
a {
	color: $link;
	text-decoration: none;
	transition: 0.25s;
	&:hover {
		color: $link;
		text-decoration: underline; }
	&[href^="#"], &[href^="tel"], &[href^="mail"] {
		&:hover {
			text-decoration: none; } }
	&.link-decorated {
		text-decoration: underline;
		font-size: 1.7rem;
		&:hover {
			text-decoration: none; } }
	&.link-more {
		font-family: $title-font;
		font-size: 1.3rem;
		color: $text; } }
.w-100 {
	width: 100%; }
.h-100 {
	height: 100%; }
.list {
	&-unstyled {
		@include list-unstyled; } }
.icon {
	display: inline-block;
	vertical-align: middle;
	top: -0.3rem;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	&-left {
		margin-right: 1rem; }
	&-right {
		margin-left: 1rem; }
	&-location {
		@include size(1.3rem, 1.7rem); }
	&-location_w {
		@include size(1.5rem, 2.1rem); }
	&-close {
		@include size(2rem); }
	&-hamb {
		@include size(2.6rem, 2rem); }
	&-arrow {
		@include size(.5rem, .9rem); }
	&-search {
		@include size(1.6rem); }
	&-profes {
		@include size(1.6rem); }
	&-reviews {
		@include size(1.6rem, 1.4rem); }
	&-calen, &-world, &-phone {
		@include size(1.6rem); }
	&-mail {
		@include size(1.8rem, 1.4rem); }
	&-list {
		@include size(2.1rem, 1.7rem); } }
.image {
	display: block;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	&-con {
		background-size: contain; }
	&-cov {
		background-size: cover; }
	&-hover {
		overflow: hidden;
		cursor: pointer;
		&:before {
			content: '';
			@include size(100%);
			background: inherit;
			position: absolute;
			top: 0;
			left: 0;
			transition: 0.4s; }
		&:hover:before {
			transform: scale(1.05, 1.05); } } }
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	display: block;
	margin-bottom: 1.2rem;
	line-height: 1.2;
	font-family: $title-font; }
h1, .h1 {
	font-size: 3rem; }
h2, .h2 {
	font-size: 2.4rem; }
h3, .h3 {
	font-size: 2.2rem; }
h4, .h4 {
	font-size: 1.8rem; }
h5, .h5 {
	font-size: 1.6rem; }
h6, .h6 {
	font-size: 1.5rem; }
.text {
	&-xs {}
	&-s {}
	&-m {}
	&-l {}
	&-xl {} }
.btn {
	display: flex;
	cursor: pointer;
	transition: 0.25s;
	justify-content: center;
	align-items: center;
	text-decoration: none!important;
	line-height: 1.2;
	text-align: center;
	&:after {
		content: "";
		display: inline-block;
		min-height: inherit;
		visibility: hidden; }
	&-s {
		height: 3.5rem;
		min-height: 3.5rem;
		padding: {
			left: 1.7rem;
			right: 1.7rem; }
		font-size: 1.3rem; }
	&-m {
		font-family: $title-font;
		height: 5.1rem;
		min-height: 5.1rem;
		padding: {
			left: 2.14rem;
			right: 2.14rem; } }
	&-blue {
		background-color: $accent;
		color: #ffffff;
		&:hover {
			background-color: darken($accent, 10%);
			color: #ffffff; } }
	&-white {
		background-color: #ffffff;
		border: .1rem solid $accent;
		color: $text;
		&:hover {
			background-color: $accent;
			color: #fff; } } }
.hidden {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	pointer-events: none; }
.row {
	&-xs {
		@include make-row(.6rem);
		> * {
			padding: {
				left: .3rem;
				right: .3rem; } } } }
.form {
	display: block;
	&-group {
		position: relative;
		&:not(:first-child) {
			margin-top: 1.2rem; } }
	&-submit {
		margin-top: 1.2rem;
		.btn {
			margin: 0 auto; } }
	&-control {
		border: .1rem solid $accent;
		height: 3.5rem;
		line-height: 3.3rem;
		padding: 0 1.5rem;
		color: #000000;
		font-size: 1.3rem; }
	textarea.form-control {
		height: 17rem;
		padding: {
			top: .7rem;
			bottom: .7rem; } }
	&-checkbox {
		@extend .hidden;
		+ .form-label {
			cursor: pointer;
			padding-left: 3rem;
			position: relative;
			margin-bottom: 0;
			display: block;
			&:before, &:after {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0; }
			&:before {
				content: '';
				@include size(1.4rem);
				border: 0.1rem solid #000000; }
			&:after {
				content: '\2714';
				@include size(1.4rem);
				line-height: 1.4rem;
				text-align: center;
				opacity: 0;
				transition: .25s; } }
		&:checked + .form-label:after {
			opacity: 1; } }
	&-radio {
		@extend .hidden;
		+ .form-label {
			cursor: pointer;
			padding-left: 3rem;
			position: relative;
			margin-bottom: 0;
			display: block;
			&:before, &:after {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 100%; }
			&:before {
				@include size(1.4rem);
				border: 0.1rem solid #000000;
				left: 0; }
			&:after {
				@include size(.6rem);
				opacity: 0;
				transition: .25s;
				background-color: #000000;
				left: .4rem; } }
		&:checked + .form-label:after {
			opacity: 1; } }
	&-profession {
		position: relative;
		margin-bottom: calc(3rem + .2vh);
		.icon-search {
			position: absolute;
			left: 1.4rem;
			top: 50%;
			transform: translateY(-50%); }
		.form-control {
			padding-left: 3.5rem; } } }
.bg {
	&-white {
		background-color: #f9f9f9;
		color: $text; } }
.baner {
	background-color: #e9e9e9;
	position: relative;
	height: 100%;
	margin: 0 auto;
	&:before {
		content: attr(data-title);
		color: #999999;
		font-family: $baner-font;
		font-size: 1.3rem;
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%); }
	img {
		position: relative;
		z-index: 2; }
	&-top {
		flex: 0 0 6rem;
		width: 100%; }
	&-content {
		margin-bottom: 2.3rem;
		.row {
			> * {
				margin-bottom: 1.5rem; } } } }
.section {
	margin-bottom: calc(3rem + 2.8vh);
	&-title {
		margin-bottom: calc(1.5rem + 2.3vh); } }
.label {
	font-size: 1.3rem;
	display: block;
	color: #ffffff;
	height: 2rem;
	line-height: 2rem;
	padding: {
		left: .8rem;
		right: .8rem; }
	&-green {
		background-color: #2f7b23; }
	&-yellow {
		background-color: #dea105; }
	&-gray {
		background-color: #7b7b7b; } }
.is-menu,
.is-menu body {
	overflow: hidden;
	position: relative;
	height: 100%; }
.is-menu {
	.header-nav {
		opacity: 1;
		visibility: visible;
		pointer-events: auto; } }
// header
.header {
	background-color: #f9f9f9;
	padding: {
		top: 3.1rem;
		bottom: 2.7rem; }
	line-height: 1.2;
	position: relative;
	&-nav {
		&_link {
			color: $text;
			&:hover {
				color: $text; } } }
	&-city {
		&_link {
			color: $accent;
			border-bottom: 0.2rem dashed $accent;
			text-decoration: none !important;
			font-family: $title-font;
			&:hover {
				color: $accent;
				border-color: transparent; } } }
	&-mob {
		&_open, &_close, &_location {
			display: block;
			cursor: pointer;
			transition: 0.25s; }
		&_close {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 0;
			pointer-events: none;
			opacity: 0;
			visibility: hidden; }
		&_location {
			display: flex;
			justify-content: center;
			align-items: center;
			@include size(7rem);
			background-color: $accent; } }
	&-dropdown {
		position: absolute;
		opacity: 0;
		pointer-events: none;
		visibility: hidden;
		transition: 0.4s;
		top: 100%;
		left: 0;
		right: 0;
		background-color: #ffffff;
		z-index: 3;
		padding: {
			top: 5rem;
			bottom: 5rem; }
		border-bottom: .1rem solid #f9f9f9;
		overflow-y: auto;
		&_list {
			&:not(:last-child) {
				margin-bottom: 5rem; }
			left: 1.2rem;
			&.column-2 {
				columns: 2; }
			li:not(:first-child) {
    			margin-top: 1.3rem; } }
		.h2 {
			color: #d6d6d6;
			margin-bottom: 2rem; } }
	&.is-dropdown {
		.header-dropdown {
			opacity: 1;
			pointer-events: auto;
			visibility: visible; } } }
.is-menu {
	.header-mob {
		&_open {
			pointer-events: none;
			opacity: 0;
			visibility: hidden; }
		&_close {
			opacity: 1;
			pointer-events: auto;
			visibility: visible; } } }
// header

// breadcrumbs
.breadcrumbs {
	padding: {
		top: calc(1rem + 1vh);
		bottom: calc(1rem + 1.3vh); }
	&-list {
		&_item {
			&:not(:first-child) {
				margin-left: 3rem;
				position: relative;
				&:before {
					content: '—';
					font-size: 1.3rem;
					color: #646464;
					left: -2rem;
					position: absolute;
					top: 50%;
					transform: translateY(-50%); } } }
		&_link {
			color: #646464;
			font-size: 1.3rem; } } }
// breadcrumbs

// promo
.promo {
	color: #ffffff;
	margin-bottom: calc(3rem + 0.3vh);
	&-box {
		padding-top: calc(3rem + 33.3vh); }
	&-block {
		height: 100%;
		padding: {
			top: 3.8rem;
			bottom: 2.9rem;
			right: 6rem; }
		.h1 {
			margin-bottom: 1.4rem; }
		p {
			line-height: 1.2;
			margin-bottom: 2.7rem; }
		.link-more {
			margin-left: 2.6rem; }
		&.bg-white {
			padding: {
				top: 3rem;
				right: 2.9rem;
				left: 2.9rem; }
			.h1 {
				margin-bottom: 2.1rem; } } }
	&-list {
		margin-bottom: 1.2rem;
		&_item:not(:first-child) {
			margin-top: 2.5rem; }
		&_link {
			display: flex;
			width: wrap;
			text-decoration: underline;
			line-height: 1.2;
			&:hover {
				text-decoration: none; }
			.image {
				@include size(3.5rem);
				flex: 0 0 3.5rem;
				margin-right: 0.9rem; } } } }
// promo

// directions
.directions {
	&-box {}
	&-block {
		border: 0.1rem solid $accent;
		padding-bottom: 101.9%;
		position: relative;
		&_inner {
			position: absolute;
			@include size(100%);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			color: $text;
			text-decoration: none !important;
			padding: {
				top: .5rem;
				bottom: 2.5rem; }
			&:before {
				content: '';
				display: block;
				height: 1.8rem; }
			&:hover {
				background-color: #f9f9f9;
				.image {
					transform: translateY(-.5rem); } } }
		.image {
			@include size(5rem);
			transition: 0.25s; }
		.h6 {
			margin-bottom: 0; } }
	.baner {
		width: 24rem;
		height: 40rem; } }
// directions

// news
.news {
	.news-box {
		margin-bottom: -3.7rem; }
	&-box {
		> * {
			margin-bottom: 3.7rem; }
		> *:not(.info-block) {
			&:nth-child(even) {
				margin: {
					left: auto;
					right: auto; } } } }
	&-detail {
		margin: {
			top: -1rem;
			bottom: calc(1.5rem + 1.7vh); } }
	&-image {
		padding-bottom: 38%;
		margin-bottom: calc(1.5rem + 2vh); } }
// news

// article
.article {
	margin-bottom: calc(3rem + 3.2vh);
	p:not(:last-child) {
		margin-bottom: calc(1.5rem + 1.9vh); }
	&-info {
		margin: {
			top: 3rem;
			bottom: calc(3rem + 1vh); }
		padding: 2rem 3rem;
		.image {
			@include size(6rem); }
		&_text {
			display: block;
			color: #646464;
			font-size: 1.3rem; }
		&_link {
			text-decoration: underline;
			&:hover {
				text-decoration: none; } } }
	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: calc(1.5rem + 1.4vh);
		li {
			padding-left: 3.4rem;
			position: relative;
			&:not(:first-child) {
				margin-top: .6rem; }
			&:before {
				content: '';
				display: block;
				@include size(.5rem);
				background-color: $accent;
				position: absolute;
				left: 0;
				border-radius: 100%;
				top: 1.1rem; } } } }
// article

// reviews
.reviews {
	&-box {
		border-bottom: .1rem solid #F0F0F0;
		.info-block {
			padding: {
				top: calc(1.5rem + 1.9vh);
				bottom: calc(1.5rem + 1.3vh); }
			position: relative;
			&:before {
				content: '';
				@include size(calc(100% - 3rem), .1rem);
				background-color: #F0F0F0;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%); } } }
	&-functional {
		margin-bottom: calc(2rem + 1vh);
		> * {
			margin-bottom: 1rem; } }
	&-rating {
		position: relative;
		&_pointer {
			content: '';
			position: absolute;
			display: block;
			@include size(2.1rem, 3.4rem);
			pointer-events: none;
			top: 0;
			background: url(../img/icon/icon-rating.svg) center no-repeat;
			transform: translateX(-1.02rem); }
		&_box {
			display: flex;
			flex-wrap: nowrap;
			position: relative;
			border-bottom: .3rem solid #E1E1E1;
			margin-bottom: 1.2rem; }
		&_item {
			cursor: pointer;
			position: relative;
			height: 3.4rem;
			position: relative;
			width: calc(100%/7);
			&:hover:after {
				background-color: darken(#e1e1e1, 10%); }
			&:after {
				content: '';
				display: block;
				@include size(.3rem, 1rem);
				background-color: #e1e1e1;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%); }
			&:first-child, &:last-child {
				width: calc(100%/16); }
			&:first-child:after {
				left: 0;
				transform: translateY(0); }
			&:last-child:after {
				left: auto;
				right: 0;
				transform: translateY(0); } }
		&_text {
			display: block;
			font-size: 1.3rem;
			color: #646464; } }
	&-date {
		&_text {
			display: block;
			color: #646464;
			font-size: 1.3rem; }
		.form-control {
			width: 7rem;
			text-align: center;
			padding: {
				left: 0;
				right: 0; } } } }
// reviews

// alphabet
.alphabet {
	&-box {
		margin: {
			bottom: .8rem;
			right: -0.90rem;
			left: -0.90rem; }
		> * {
			padding: {
				left: .90rem;
				right: .90rem; } } }
	&-link {
		display: block;
		min-width: 1.6rem;
		border-bottom: .2rem solid transparent;
		text-transform: uppercase;
		text-align: center;
		color: $text;
		font-size: 1.6rem;
		&:hover, &.is-active {
			border-color: $accent; }
		&.is-active {
			font-weight: 700; } } }
// alphabet

// professions
.professions {
	&-box {
		margin: {
			top: 2.6rem;
			left: -.75rem;
			right: -.75rem; }
		> * {
			margin-bottom: 1.5rem;
			padding: {
				left: 0.75rem;
				right: 0.75rem; } } }
	&-block {
		display: flex;
		background-color: #f9f9f9;
		height: 7.5rem;
		min-height: 7.5rem;
		align-items: center;
		justify-content: center;
		font-family: $title-font;
		text-decoration: none !important;
		color: $text;
		&:hover {
			background-color: darken(#f9f9f9, 10%);
			color: $text; } } }
// professions

// info
.info {
	&-block {
		text-decoration: none!important;
		color: #000000;
		line-height: 1.2;
		.image {
			@include size(10.1rem); }
		&_date, &_category {
			display: block;
			font-size: 1.3rem; }
		&_date {
			color: #646464;
			margin-bottom: 1.1rem; }
		&_category {
			color: #2a6883;
			position: relative;
			&:before {
				content: '';
				@include size(.6rem);
				border-radius: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				background-color: $accent;
				left: -1.8rem; } }
		&_content {
			.row {
				margin-bottom: .9rem; }
			p {
				line-height: 1.5;
				font-size: 1.3rem;
				color: #646464; } } }
	&-count {
		margin-top: 2.5rem;
		> *:not(:first-child) {
			margin-left: 2.4vw; }
		&_text {
			display: block;
			font-size: 1.3rem;
			color: #646464; } }
	&-functional {
		font-size: 1.3rem;
		margin-top: 2rem;
		strong {
			font-size: 1.5rem; }
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none; } }
		.btn {
			min-width: 16.3rem; } } }
.info, .doors {
	&-block {
		.row > .col-auto > .info-block_date {
			margin-bottom: 0; } } }
// info

// location
.location {
	padding-top: 2.4vh;
	.baner {
		@include size(30rem, 10rem);
		&:not(:first-child) {
			margin-top: 4rem; } }
	&-list {
		margin-bottom: 1.8rem;
		&_item:not(:first-child) {
			margin-top: .53rem; } }
	.link-more {
		margin-left: .3rem; } }
// location

// doors
.doors {
	&-box {
		border-bottom: 1px solid #F0F0F0;
		.h1 {
			color: #e9e9e9;
			margin-bottom: -1.2rem; } }
	&-block {
		display: block;
		border-top: 1px solid #F0F0F0;
		padding: {
			top: calc(1.5rem + 1.2vh);
			bottom: calc(1.5rem + 2vh); }
		color: $text;
		text-decoration: none !important; }
	&-item {
		display: block;
		color: $text;
		text-decoration: none !important;
		line-height: 1.2;
		margin-top: calc(3rem + 1.2vh);
		&_image {
			display: flex;
			align-items: center;
			justify-content: center;
			@include size(10rem);
			background-color: #f9f9f9;
			.image {
				@include size(5.3rem); } }
		&_content {
			> .row {
				margin-bottom: .9rem; }
			p {
				font-size: 1.3rem;
				color: #646464;
				line-height: 1.5; } } } }
.box {
	border-bottom: .1rem solid #F0F0F0; }
// doors

// colleges
.colleges {
	&-box {
		border-bottom: 1px solid #F0F0F0;
		.doors-item_content {
			> .row {
				margin-bottom: 0.6rem; }
			.h4 {
				margin-bottom: 0.8rem;
				color: $text; } }
		.doors-block {
			a:hover {
				text-decoration: none; } }
		.doors-block:hover {
			.h4 {
				color: $link; } } }
	&-description {
		margin-bottom: 2.5rem; }
	&-map {
		display: none; }
	&-top {
		padding: calc(1.5rem + 4vh) calc(3rem + 3.4vh) calc(1.5rem + 3.4vh);
		position: relative;
		display: block;
		text-decoration: none !important;
		.image {
			@include size(5.3rem); }
		&:before {
			content: 'Реклама';
			height: 100%;
			text-align: center;
			position: absolute;
			left: 0;
			top: 0;
			background-color: $accent;
			color: #ffffff;
			width: 2.5rem;
			writing-mode: vertical-rl;
			display: block;
			transform: rotate(-180deg); }
		.h4 {
			margin-bottom: .6rem; }
		p {
			font-size: 1.3rem;
			color: #646464; } }
	&-search {
		margin: {
			left: -.75rem;
			right: -.75rem;
			bottom: 1.5rem; }
		> * {
			padding: {
				left: 0.75rem;
				right: 0.75rem; } }
		.btn {
			width: 30rem;
			justify-content: space-between;
			&:after {
				display: none; }
			.icon-list {
				display: none; } } }
	&-status {
		margin: {
			left: -.35rem;
			right: -.35rem;
			bottom: -.5rem; }
		> * {
			padding: {
				left: 0.35rem;
				right: 0.35rem; }
			margin-bottom: 0.5rem; } } }
#map {
	height: 60vh; }
// colleges

// college
.college {
	margin-bottom: 2.4rem;
	.btn-s {
		font-family: $title-font;
		font-size: 1.5rem; }
	&-image {
		@include size(12.8rem);
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #f9f9f9;
		.image {
			@include size(7.4rem); } }
	&-info {
		margin-bottom: 1.4rem;
		> *:not(:first-child) {
			.college-info_text:before {
				content: '';
				@include size(.6rem);
				border-radius: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				background-color: $accent;
				left: -1.8rem; } }
		&_text {
			font-size: 1.3rem;
			color: #646464;
			position: relative; }
		&_type {
			color: #2A6883; } }
	&-control {
		border-bottom: .1rem solid #F0F0F0;
		margin-bottom: 3rem;
		.row {
			margin: {
				left: -.6rem;
				right: -.6rem; }
			> * {
				padding: {
					left: .6rem;
					right: .6rem; } } }
		&_link {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 4.2rem;
			min-height: 4.2rem;
			cursor: pointer;
			border: .1rem solid #F0F0F0;
			border-bottom: none;
			position: relative;
			font-family: $title-font;
			padding: {
				left: 1.6rem;
				right: 1.6rem; }
			&:hover, &.is-active {
				color: #2A6883; }
			&.is-active {
				&:after {
					content: '';
					position: absolute;
					bottom: -.1rem;
					left: 0;
					height: 0.1rem;
					width: 100%;
					background-color: #ffffff; } } } }
	&-tab {
		&:not(:first-child) {
			display: none; }
		.doors-block {
			border-top: none;
			border-bottom: .1rem solid #f0f0f0;
			&:first-child {
				margin-top: -3rem; } }
		.reviews-box {
			margin-top: -3rem;
			.info-block:first-child:before {
				display: none; } } }
	&-tabs {
		margin-bottom: 3rem; }
	&-contact {
		padding: calc(1.5rem + 2vh) calc(1.5rem + 2.4vh);
		margin-bottom: calc(3rem + .7vh);
		.row > *:not(:first-child) {
			margin-left: 1.7vw; }
		&_title {
			display: block;
			font-size: 1.3rem;
			color: #646464;
			margin-bottom: .5rem; }
		&_text, &_link {
			font-family: $title-font; }
		&_text {
			color: $text; } }
	&-functional {
		margin-bottom: -1rem;
		> * {
			margin-bottom: 1rem; } } }
// college

// content
.content {
	padding-right: 5rem; }
// content

// aside
.aside {
	.baner:not(:last-child) {
		margin-bottom: calc(3rem + 1vh); }
	h2 {
		margin-bottom: calc(1.5rem + .8vh); }
	&-list {
		margin-bottom: calc(3rem + 1vh);
		li:not(:first-child) {
			line-height: 1.2;
			margin-top: 1.3rem; } } }
// aside

// pagination
.pagination {
	&-link {
		display: block;
		font-size: 1.6rem;
		color: $text;
		padding-bottom: .3rem; }
	&-box {
		margin-top: 5.5rem;
		> *:not(:first-child) {
			margin-left: 2.1rem; }
		span {
			color: #646464; }
		a {
			font-weight: 700;
			border-bottom: .2rem solid $accent;
			min-width: 1.6rem;
			text-align: center; } } }
// pagination

// filter
.filter {
	&-box {
		margin-bottom: 4.9rem; }
	&-date {
		margin: {
			top: -1.2rem;
			bottom: calc(3rem + .5vh); }
		.form {
			&-control {
				width: 22.4rem;
				padding-right: 4rem;
				cursor: pointer; }
			&-group {
				.icon {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					pointer-events: none;
					right: 1.8rem; } } } }
	&-block {
		margin-bottom: 1rem;
		&_link {
			height: 3.5rem;
			display: flex;
			min-height: 3.5rem;
			align-items: center;
			justify-content: center;
			padding: {
				left: 1.6rem;
				right: 1.6rem; }
			font-family: $title-font;
			border: .1rem solid $accent;
			background-color: #ffffff;
			text-decoration: none!important;
			color: $text;
			&:hover {
				background-color: $accent;
				color: #ffffff; }
			&.is-active {
				background-color: $accent;
				color: #ffffff; } } } }
// filter

// tabs
.tabs {
	&-control {
		margin-bottom: calc(3rem + 2vh); }
	&-control {
		> * {
			margin-bottom: 1rem; }
		&_link {
			height: 3.5rem;
			display: flex;
			min-height: 3.5rem;
			align-items: center;
			justify-content: center;
			padding: {
				left: 1.6rem;
				right: 1.6rem; }
			font-family: $title-font;
			border: .1rem solid $accent;
			background-color: #ffffff;
			text-decoration: none!important;
			color: $text;
			&:hover {
				background-color: $accent;
				color: #ffffff; }
			&.is-active {
				background-color: $accent;
				color: #ffffff; } } } }
// tabs

// popup
.popup {
	display: none;
	width: 40rem;
	padding: 3.5rem 4rem;
	.h1 {
		text-align: center;
		margin-bottom: 1.9rem; }
	&-info {
		display: block;
		font-size: 1.3rem;
		color: #646464; }
	.form {
		margin-bottom: 2.6rem;
		&-submit {
			.btn {
				width: 100%;
				height: 4rem;
				min-height: 4rem;
				font-size: 1.5rem;
				font-family: $title-font; } } }
	&#reviews {
		width: 72rem;
		.h1 {
			text-align: left; }
		.form-group:not(:first-child) {
			margin-top: 3rem; }
		.article-info {
			padding: 1.6rem 2rem;
			.row {
				margin: {
					left: -1rem;
					right: -1rem; }
				> * {
					padding: {
						left: 1rem;
						right: 1rem; } } } } } }
// popup

// footer
.footer {
	background-color: #f9f9f9;
	padding: {
		top: 2.8rem;
		bottom: 2.8rem; }
	&-copy {
		&_text {
			display: block;
			font-size: 1.3rem;
			color: #646464;
			margin: {
				left: 1.4rem;
				right: 1.4rem; } } }
	&-author {
		display: flex;
		align-items: center;
		&_text {
			display: block;
			font-family: $baner-font;
			font-size: 1.3rem;
			color: #646464;
			margin-right: 0.4rem; } } }
// footer

@import "media";
