@charset "UTF-8";
/*! Bootstrap Reboot v4.3.1 (https://getbootstrap.com/) Copyright 2011-2019 The Bootstrap Authors Copyright 2011-2019 Twitter, Inc. Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE) Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md) */
*, *::before, *::after { box-sizing: border-box; }

body { margin: 0; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #212529; background-color: #fff; -webkit-text-size-adjust: 100%; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

[tabindex="-1"]:focus:not(:focus-visible) { outline: 0 !important; }

hr { margin: 1rem 0; color: inherit; background-color: currentColor; border: 0; opacity: 0.25; }

hr:not([size]) { height: 1px; }

h1, h2, h3, h4, h5, h6 { margin-top: 0; margin-bottom: 0.5rem; font-weight: 500; line-height: 1.2; }

h1 { font-size: 2.5rem; }

@media (max-width: 1200px) { h1 { font-size: calc(1.375rem + 1.5vw) ; } }

h2 { font-size: 2rem; }

@media (max-width: 1200px) { h2 { font-size: calc(1.325rem + 0.9vw) ; } }

h3 { font-size: 1.75rem; }

@media (max-width: 1200px) { h3 { font-size: calc(1.3rem + 0.6vw) ; } }

h4 { font-size: 1.5rem; }

@media (max-width: 1200px) { h4 { font-size: calc(1.275rem + 0.3vw) ; } }

h5 { font-size: 1.25rem; }

h6 { font-size: 1rem; }

p { margin-top: 0; margin-bottom: 1rem; }

abbr[title], abbr[data-original-title] { text-decoration: underline; text-decoration: underline dotted; cursor: help; text-decoration-skip-ink: none; }

address { margin-bottom: 1rem; font-style: normal; line-height: inherit; }

ol, ul { padding-left: 2rem; }

ol, ul, dl { margin-top: 0; margin-bottom: 1rem; }

ol ol, ul ul, ol ul, ul ol { margin-bottom: 0; }

dt { font-weight: 700; }

dd { margin-bottom: .5rem; margin-left: 0; }

blockquote { margin: 0 0 1rem; }

b, strong { font-weight: bolder; }

small { font-size: 0.875em; }

sub, sup { position: relative; font-size: 0.75em; line-height: 0; vertical-align: baseline; }

sub { bottom: -.25em; }

sup { top: -.5em; }

a { color: #007bff; text-decoration: none; }

a:hover { color: #0056b3; text-decoration: underline; }

a:not([href]), a:not([href]):hover { color: inherit; text-decoration: none; }

pre, code, kbd, samp { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; font-size: 1em; }

pre { display: block; margin-top: 0; margin-bottom: 1rem; overflow: auto; font-size: 0.875em; }

pre code { font-size: inherit; color: inherit; word-break: normal; }

code { font-size: 0.875em; color: #e83e8c; word-wrap: break-word; }

a > code { color: inherit; }

kbd { padding: 0.2rem 0.4rem; font-size: 0.875em; color: #fff; background-color: #212529; border-radius: 0.2rem; }

kbd kbd { padding: 0; font-size: 1em; font-weight: 700; }

figure { margin: 0 0 1rem; }

img { vertical-align: middle; }

svg { overflow: hidden; vertical-align: middle; }

table { border-collapse: collapse; }

caption { padding-top: 0.5rem; padding-bottom: 0.5rem; color: #6c757d; text-align: left; caption-side: bottom; }

th { text-align: inherit; }

label { display: inline-block; margin-bottom: 0.5rem; }

button { border-radius: 0; }

button:focus { outline: 1px dotted; outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea { margin: 0; font-family: inherit; font-size: inherit; line-height: inherit; }

button, input { overflow: visible; }

button, select { text-transform: none; }

select { word-wrap: normal; }

[list]::-webkit-calendar-picker-indicator { display: none; }

button, [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) { cursor: pointer; }

::-moz-focus-inner { padding: 0; border-style: none; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] { -webkit-appearance: textfield; }

textarea { overflow: auto; resize: vertical; -webkit-appearance: textfield; }

fieldset { min-width: 0; padding: 0; margin: 0; border: 0; }

legend { float: left; width: 100%; padding: 0; margin-bottom: 0.5rem; font-size: 1.5rem; line-height: inherit; color: inherit; white-space: normal; }

@media (max-width: 1200px) { legend { font-size: calc(1.275rem + 0.3vw) ; } }

mark { padding: 0.2em; background-color: #fcf8e3; }

progress { vertical-align: baseline; }

::-webkit-datetime-edit { overflow: visible; line-height: 0; }

[type="search"] { outline-offset: -2px; -webkit-appearance: textfield; }

::-webkit-search-decoration { -webkit-appearance: none; }

::-webkit-color-swatch-wrapper { padding: 0; }

::-webkit-file-upload-button { font: inherit; -webkit-appearance: button; }

output { display: inline-block; }

summary { display: list-item; cursor: pointer; }

template { display: none; }

main { display: block; }

[hidden] { display: none !important; }

/*! Bootstrap Grid v4.3.1 (https://getbootstrap.com/) Copyright 2011-2019 The Bootstrap Authors Copyright 2011-2019 Twitter, Inc. Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE) */
html { box-sizing: border-box; }

*, *::before, *::after { box-sizing: inherit; }

.container { width: 100%; padding-right: 1.5rem; padding-left: 1.5rem; margin-right: auto; margin-left: auto; }

@media (min-width: 410px) { .container { max-width: 380px; } }

@media (min-width: 576px) { .container { max-width: 540px; } }

@media (min-width: 768px) { .container { max-width: 720px; } }

@media (min-width: 992px) { .container { max-width: 960px; } }

@media (min-width: 1200px) { .container { max-width: 1170px; } }

@media (min-width: 1300px) { .container { max-width: 1270px; } }

.container-fluid, .container-xs, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl { width: 100%; padding-right: 1.5rem; padding-left: 1.5rem; margin-right: auto; margin-left: auto; }

@media (min-width: 410px) { .container, .container-xs { max-width: 380px; } }

@media (min-width: 576px) { .container, .container-xs, .container-sm { max-width: 540px; } }

@media (min-width: 768px) { .container, .container-xs, .container-sm, .container-md { max-width: 720px; } }

@media (min-width: 992px) { .container, .container-xs, .container-sm, .container-md, .container-lg { max-width: 960px; } }

@media (min-width: 1200px) { .container, .container-xs, .container-sm, .container-md, .container-lg, .container-xl { max-width: 1170px; } }

@media (min-width: 1300px) { .container, .container-xs, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl { max-width: 1270px; } }

.row { display: flex; flex-wrap: wrap; margin-right: -1.5rem; margin-left: -1.5rem; }

.no-gutters { margin-right: 0; margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto { position: relative; width: 100%; padding-right: 1.5rem; padding-left: 1.5rem; }

.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }

.row-cols-1 > * { flex: 0 0 100%; max-width: 100%; }

.row-cols-2 > * { flex: 0 0 50%; max-width: 50%; }

.row-cols-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }

.row-cols-4 > * { flex: 0 0 25%; max-width: 25%; }

.row-cols-5 > * { flex: 0 0 20%; max-width: 20%; }

.row-cols-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }

.col-auto { flex: 0 0 auto; width: auto; max-width: 100%; }

.col-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }

.col-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }

.col-3 { flex: 0 0 25%; max-width: 25%; }

.col-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }

.col-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }

.col-6 { flex: 0 0 50%; max-width: 50%; }

.col-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }

.col-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }

.col-9 { flex: 0 0 75%; max-width: 75%; }

.col-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }

.col-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }

.col-12 { flex: 0 0 100%; max-width: 100%; }

.offset-1 { margin-left: 8.33333%; }

.offset-2 { margin-left: 16.66667%; }

.offset-3 { margin-left: 25%; }

.offset-4 { margin-left: 33.33333%; }

.offset-5 { margin-left: 41.66667%; }

.offset-6 { margin-left: 50%; }

.offset-7 { margin-left: 58.33333%; }

.offset-8 { margin-left: 66.66667%; }

.offset-9 { margin-left: 75%; }

.offset-10 { margin-left: 83.33333%; }

.offset-11 { margin-left: 91.66667%; }

@media (min-width: 410px) { .col-xs { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-xs-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-xs-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-xs-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-xs-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-xs-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-xs-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xs-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xs-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xs-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xs-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xs-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-xs-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-xs-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xs-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-xs-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-xs-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xs-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-xs-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-xs-12 { flex: 0 0 100%; max-width: 100%; }
  .offset-xs-0 { margin-left: 0; }
  .offset-xs-1 { margin-left: 8.33333%; }
  .offset-xs-2 { margin-left: 16.66667%; }
  .offset-xs-3 { margin-left: 25%; }
  .offset-xs-4 { margin-left: 33.33333%; }
  .offset-xs-5 { margin-left: 41.66667%; }
  .offset-xs-6 { margin-left: 50%; }
  .offset-xs-7 { margin-left: 58.33333%; }
  .offset-xs-8 { margin-left: 66.66667%; }
  .offset-xs-9 { margin-left: 75%; }
  .offset-xs-10 { margin-left: 83.33333%; }
  .offset-xs-11 { margin-left: 91.66667%; } }

@media (min-width: 576px) { .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-sm-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-sm-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-sm-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-sm-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-sm-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-sm-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-sm-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-sm-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-sm-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-sm-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-sm-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.33333%; }
  .offset-sm-2 { margin-left: 16.66667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.33333%; }
  .offset-sm-5 { margin-left: 41.66667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.33333%; }
  .offset-sm-8 { margin-left: 66.66667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.33333%; }
  .offset-sm-11 { margin-left: 91.66667%; } }

@media (min-width: 768px) { .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-md-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-md-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-md-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-md-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-md-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-md-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-md-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-md-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-md-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-md-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-md-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-md-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.33333%; }
  .offset-md-2 { margin-left: 16.66667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.33333%; }
  .offset-md-5 { margin-left: 41.66667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.33333%; }
  .offset-md-8 { margin-left: 66.66667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.33333%; }
  .offset-md-11 { margin-left: 91.66667%; } }

@media (min-width: 992px) { .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-lg-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-lg-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-lg-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-lg-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-lg-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-lg-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-lg-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-lg-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-lg-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-lg-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-lg-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.33333%; }
  .offset-lg-2 { margin-left: 16.66667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.33333%; }
  .offset-lg-5 { margin-left: 41.66667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.33333%; }
  .offset-lg-8 { margin-left: 66.66667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.33333%; }
  .offset-lg-11 { margin-left: 91.66667%; } }

@media (min-width: 1200px) { .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-xl-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-xl-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-xl-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-xl-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-xl-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-xl-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xl-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-xl-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-xl-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-xl-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.33333%; }
  .offset-xl-2 { margin-left: 16.66667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.33333%; }
  .offset-xl-5 { margin-left: 41.66667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.33333%; }
  .offset-xl-8 { margin-left: 66.66667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.33333%; }
  .offset-xl-11 { margin-left: 91.66667%; } }

@media (min-width: 1300px) { .col-xxl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-xxl-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-xxl-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-xxl-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-xxl-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-xxl-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-xxl-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xxl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xxl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xxl-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xxl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xxl-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-xxl-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-xxl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xxl-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-xxl-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-xxl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xxl-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-xxl-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-xxl-12 { flex: 0 0 100%; max-width: 100%; }
  .offset-xxl-0 { margin-left: 0; }
  .offset-xxl-1 { margin-left: 8.33333%; }
  .offset-xxl-2 { margin-left: 16.66667%; }
  .offset-xxl-3 { margin-left: 25%; }
  .offset-xxl-4 { margin-left: 33.33333%; }
  .offset-xxl-5 { margin-left: 41.66667%; }
  .offset-xxl-6 { margin-left: 50%; }
  .offset-xxl-7 { margin-left: 58.33333%; }
  .offset-xxl-8 { margin-left: 66.66667%; }
  .offset-xxl-9 { margin-left: 75%; }
  .offset-xxl-10 { margin-left: 83.33333%; }
  .offset-xxl-11 { margin-left: 91.66667%; } }

.d-none { display: none !important; }

.d-inline { display: inline !important; }

.d-inline-block { display: inline-block !important; }

.d-block { display: block !important; }

.d-table { display: table !important; }

.d-table-row { display: table-row !important; }

.d-table-cell { display: table-cell !important; }

.d-flex { display: flex !important; }

.d-inline-flex { display: inline-flex !important; }

.flex-fill { flex: 1 1 auto !important; }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-grow-0 { flex-grow: 0 !important; }

.flex-grow-1 { flex-grow: 1 !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

.order-first { order: -1 !important; }

.order-0 { order: 0 !important; }

.order-1 { order: 1 !important; }

.order-2 { order: 2 !important; }

.order-3 { order: 3 !important; }

.order-4 { order: 4 !important; }

.order-5 { order: 5 !important; }

.order-last { order: 6 !important; }

.m-0 { margin: 0 !important; }

.m-1 { margin: 0.25rem !important; }

.m-2 { margin: 0.5rem !important; }

.m-3 { margin: 1rem !important; }

.m-4 { margin: 1.5rem !important; }

.m-5 { margin: 3rem !important; }

.m-auto { margin: auto !important; }

.mx-0 { margin-right: 0 !important; margin-left: 0 !important; }

.mx-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }

.mx-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }

.mx-3 { margin-right: 1rem !important; margin-left: 1rem !important; }

.mx-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }

.mx-5 { margin-right: 3rem !important; margin-left: 3rem !important; }

.mx-auto { margin-right: auto !important; margin-left: auto !important; }

.my-0 { margin-top: 0 !important; margin-bottom: 0 !important; }

.my-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }

.my-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }

.my-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }

.my-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }

.my-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }

.my-auto { margin-top: auto !important; margin-bottom: auto !important; }

.mt-0 { margin-top: 0 !important; }

.mt-1 { margin-top: 0.25rem !important; }

.mt-2 { margin-top: 0.5rem !important; }

.mt-3 { margin-top: 1rem !important; }

.mt-4 { margin-top: 1.5rem !important; }

.mt-5 { margin-top: 3rem !important; }

.mt-auto { margin-top: auto !important; }

.mr-0 { margin-right: 0 !important; }

.mr-1 { margin-right: 0.25rem !important; }

.mr-2 { margin-right: 0.5rem !important; }

.mr-3 { margin-right: 1rem !important; }

.mr-4 { margin-right: 1.5rem !important; }

.mr-5 { margin-right: 3rem !important; }

.mr-auto { margin-right: auto !important; }

.mb-0 { margin-bottom: 0 !important; }

.mb-1 { margin-bottom: 0.25rem !important; }

.mb-2 { margin-bottom: 0.5rem !important; }

.mb-3 { margin-bottom: 1rem !important; }

.mb-4 { margin-bottom: 1.5rem !important; }

.mb-5 { margin-bottom: 3rem !important; }

.mb-auto { margin-bottom: auto !important; }

.ml-0 { margin-left: 0 !important; }

.ml-1 { margin-left: 0.25rem !important; }

.ml-2 { margin-left: 0.5rem !important; }

.ml-3 { margin-left: 1rem !important; }

.ml-4 { margin-left: 1.5rem !important; }

.ml-5 { margin-left: 3rem !important; }

.ml-auto { margin-left: auto !important; }

.m-n1 { margin: -0.25rem !important; }

.m-n2 { margin: -0.5rem !important; }

.m-n3 { margin: -1rem !important; }

.m-n4 { margin: -1.5rem !important; }

.m-n5 { margin: -3rem !important; }

.mx-n1 { margin-right: -0.25rem !important; margin-left: -0.25rem !important; }

.mx-n2 { margin-right: -0.5rem !important; margin-left: -0.5rem !important; }

.mx-n3 { margin-right: -1rem !important; margin-left: -1rem !important; }

.mx-n4 { margin-right: -1.5rem !important; margin-left: -1.5rem !important; }

.mx-n5 { margin-right: -3rem !important; margin-left: -3rem !important; }

.my-n1 { margin-top: -0.25rem !important; margin-bottom: -0.25rem !important; }

.my-n2 { margin-top: -0.5rem !important; margin-bottom: -0.5rem !important; }

.my-n3 { margin-top: -1rem !important; margin-bottom: -1rem !important; }

.my-n4 { margin-top: -1.5rem !important; margin-bottom: -1.5rem !important; }

.my-n5 { margin-top: -3rem !important; margin-bottom: -3rem !important; }

.mt-n1 { margin-top: -0.25rem !important; }

.mt-n2 { margin-top: -0.5rem !important; }

.mt-n3 { margin-top: -1rem !important; }

.mt-n4 { margin-top: -1.5rem !important; }

.mt-n5 { margin-top: -3rem !important; }

.mr-n1 { margin-right: -0.25rem !important; }

.mr-n2 { margin-right: -0.5rem !important; }

.mr-n3 { margin-right: -1rem !important; }

.mr-n4 { margin-right: -1.5rem !important; }

.mr-n5 { margin-right: -3rem !important; }

.mb-n1 { margin-bottom: -0.25rem !important; }

.mb-n2 { margin-bottom: -0.5rem !important; }

.mb-n3 { margin-bottom: -1rem !important; }

.mb-n4 { margin-bottom: -1.5rem !important; }

.mb-n5 { margin-bottom: -3rem !important; }

.ml-n1 { margin-left: -0.25rem !important; }

.ml-n2 { margin-left: -0.5rem !important; }

.ml-n3 { margin-left: -1rem !important; }

.ml-n4 { margin-left: -1.5rem !important; }

.ml-n5 { margin-left: -3rem !important; }

.p-0 { padding: 0 !important; }

.p-1 { padding: 0.25rem !important; }

.p-2 { padding: 0.5rem !important; }

.p-3 { padding: 1rem !important; }

.p-4 { padding: 1.5rem !important; }

.p-5 { padding: 3rem !important; }

.px-0 { padding-right: 0 !important; padding-left: 0 !important; }

.px-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }

.px-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }

.px-3 { padding-right: 1rem !important; padding-left: 1rem !important; }

.px-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }

.px-5 { padding-right: 3rem !important; padding-left: 3rem !important; }

.py-0 { padding-top: 0 !important; padding-bottom: 0 !important; }

.py-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }

.py-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }

.py-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }

.py-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }

.py-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }

.pt-0 { padding-top: 0 !important; }

.pt-1 { padding-top: 0.25rem !important; }

.pt-2 { padding-top: 0.5rem !important; }

.pt-3 { padding-top: 1rem !important; }

.pt-4 { padding-top: 1.5rem !important; }

.pt-5 { padding-top: 3rem !important; }

.pr-0 { padding-right: 0 !important; }

.pr-1 { padding-right: 0.25rem !important; }

.pr-2 { padding-right: 0.5rem !important; }

.pr-3 { padding-right: 1rem !important; }

.pr-4 { padding-right: 1.5rem !important; }

.pr-5 { padding-right: 3rem !important; }

.pb-0 { padding-bottom: 0 !important; }

.pb-1 { padding-bottom: 0.25rem !important; }

.pb-2 { padding-bottom: 0.5rem !important; }

.pb-3 { padding-bottom: 1rem !important; }

.pb-4 { padding-bottom: 1.5rem !important; }

.pb-5 { padding-bottom: 3rem !important; }

.pl-0 { padding-left: 0 !important; }

.pl-1 { padding-left: 0.25rem !important; }

.pl-2 { padding-left: 0.5rem !important; }

.pl-3 { padding-left: 1rem !important; }

.pl-4 { padding-left: 1.5rem !important; }

.pl-5 { padding-left: 3rem !important; }

@media (min-width: 410px) { .d-xs-none { display: none !important; }
  .d-xs-inline { display: inline !important; }
  .d-xs-inline-block { display: inline-block !important; }
  .d-xs-block { display: block !important; }
  .d-xs-table { display: table !important; }
  .d-xs-table-row { display: table-row !important; }
  .d-xs-table-cell { display: table-cell !important; }
  .d-xs-flex { display: flex !important; }
  .d-xs-inline-flex { display: inline-flex !important; }
  .flex-xs-fill { flex: 1 1 auto !important; }
  .flex-xs-row { flex-direction: row !important; }
  .flex-xs-column { flex-direction: column !important; }
  .flex-xs-row-reverse { flex-direction: row-reverse !important; }
  .flex-xs-column-reverse { flex-direction: column-reverse !important; }
  .flex-xs-grow-0 { flex-grow: 0 !important; }
  .flex-xs-grow-1 { flex-grow: 1 !important; }
  .justify-content-xs-start { justify-content: flex-start !important; }
  .justify-content-xs-end { justify-content: flex-end !important; }
  .justify-content-xs-center { justify-content: center !important; }
  .justify-content-xs-between { justify-content: space-between !important; }
  .justify-content-xs-around { justify-content: space-around !important; }
  .align-items-xs-start { align-items: flex-start !important; }
  .align-items-xs-end { align-items: flex-end !important; }
  .align-items-xs-center { align-items: center !important; }
  .align-items-xs-baseline { align-items: baseline !important; }
  .align-items-xs-stretch { align-items: stretch !important; }
  .align-content-xs-start { align-content: flex-start !important; }
  .align-content-xs-end { align-content: flex-end !important; }
  .align-content-xs-center { align-content: center !important; }
  .align-content-xs-between { align-content: space-between !important; }
  .align-content-xs-around { align-content: space-around !important; }
  .align-content-xs-stretch { align-content: stretch !important; }
  .align-self-xs-auto { align-self: auto !important; }
  .align-self-xs-start { align-self: flex-start !important; }
  .align-self-xs-end { align-self: flex-end !important; }
  .align-self-xs-center { align-self: center !important; }
  .align-self-xs-baseline { align-self: baseline !important; }
  .align-self-xs-stretch { align-self: stretch !important; }
  .order-xs-first { order: -1 !important; }
  .order-xs-0 { order: 0 !important; }
  .order-xs-1 { order: 1 !important; }
  .order-xs-2 { order: 2 !important; }
  .order-xs-3 { order: 3 !important; }
  .order-xs-4 { order: 4 !important; }
  .order-xs-5 { order: 5 !important; }
  .order-xs-last { order: 6 !important; }
  .m-xs-0 { margin: 0 !important; }
  .m-xs-1 { margin: 0.25rem !important; }
  .m-xs-2 { margin: 0.5rem !important; }
  .m-xs-3 { margin: 1rem !important; }
  .m-xs-4 { margin: 1.5rem !important; }
  .m-xs-5 { margin: 3rem !important; }
  .m-xs-auto { margin: auto !important; }
  .mx-xs-0 { margin-right: 0 !important; margin-left: 0 !important; }
  .mx-xs-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
  .mx-xs-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
  .mx-xs-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
  .mx-xs-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
  .mx-xs-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
  .mx-xs-auto { margin-right: auto !important; margin-left: auto !important; }
  .my-xs-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
  .my-xs-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
  .my-xs-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
  .my-xs-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
  .my-xs-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
  .my-xs-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
  .my-xs-auto { margin-top: auto !important; margin-bottom: auto !important; }
  .mt-xs-0 { margin-top: 0 !important; }
  .mt-xs-1 { margin-top: 0.25rem !important; }
  .mt-xs-2 { margin-top: 0.5rem !important; }
  .mt-xs-3 { margin-top: 1rem !important; }
  .mt-xs-4 { margin-top: 1.5rem !important; }
  .mt-xs-5 { margin-top: 3rem !important; }
  .mt-xs-auto { margin-top: auto !important; }
  .mr-xs-0 { margin-right: 0 !important; }
  .mr-xs-1 { margin-right: 0.25rem !important; }
  .mr-xs-2 { margin-right: 0.5rem !important; }
  .mr-xs-3 { margin-right: 1rem !important; }
  .mr-xs-4 { margin-right: 1.5rem !important; }
  .mr-xs-5 { margin-right: 3rem !important; }
  .mr-xs-auto { margin-right: auto !important; }
  .mb-xs-0 { margin-bottom: 0 !important; }
  .mb-xs-1 { margin-bottom: 0.25rem !important; }
  .mb-xs-2 { margin-bottom: 0.5rem !important; }
  .mb-xs-3 { margin-bottom: 1rem !important; }
  .mb-xs-4 { margin-bottom: 1.5rem !important; }
  .mb-xs-5 { margin-bottom: 3rem !important; }
  .mb-xs-auto { margin-bottom: auto !important; }
  .ml-xs-0 { margin-left: 0 !important; }
  .ml-xs-1 { margin-left: 0.25rem !important; }
  .ml-xs-2 { margin-left: 0.5rem !important; }
  .ml-xs-3 { margin-left: 1rem !important; }
  .ml-xs-4 { margin-left: 1.5rem !important; }
  .ml-xs-5 { margin-left: 3rem !important; }
  .ml-xs-auto { margin-left: auto !important; }
  .m-xs-n1 { margin: -0.25rem !important; }
  .m-xs-n2 { margin: -0.5rem !important; }
  .m-xs-n3 { margin: -1rem !important; }
  .m-xs-n4 { margin: -1.5rem !important; }
  .m-xs-n5 { margin: -3rem !important; }
  .mx-xs-n1 { margin-right: -0.25rem !important; margin-left: -0.25rem !important; }
  .mx-xs-n2 { margin-right: -0.5rem !important; margin-left: -0.5rem !important; }
  .mx-xs-n3 { margin-right: -1rem !important; margin-left: -1rem !important; }
  .mx-xs-n4 { margin-right: -1.5rem !important; margin-left: -1.5rem !important; }
  .mx-xs-n5 { margin-right: -3rem !important; margin-left: -3rem !important; }
  .my-xs-n1 { margin-top: -0.25rem !important; margin-bottom: -0.25rem !important; }
  .my-xs-n2 { margin-top: -0.5rem !important; margin-bottom: -0.5rem !important; }
  .my-xs-n3 { margin-top: -1rem !important; margin-bottom: -1rem !important; }
  .my-xs-n4 { margin-top: -1.5rem !important; margin-bottom: -1.5rem !important; }
  .my-xs-n5 { margin-top: -3rem !important; margin-bottom: -3rem !important; }
  .mt-xs-n1 { margin-top: -0.25rem !important; }
  .mt-xs-n2 { margin-top: -0.5rem !important; }
  .mt-xs-n3 { margin-top: -1rem !important; }
  .mt-xs-n4 { margin-top: -1.5rem !important; }
  .mt-xs-n5 { margin-top: -3rem !important; }
  .mr-xs-n1 { margin-right: -0.25rem !important; }
  .mr-xs-n2 { margin-right: -0.5rem !important; }
  .mr-xs-n3 { margin-right: -1rem !important; }
  .mr-xs-n4 { margin-right: -1.5rem !important; }
  .mr-xs-n5 { margin-right: -3rem !important; }
  .mb-xs-n1 { margin-bottom: -0.25rem !important; }
  .mb-xs-n2 { margin-bottom: -0.5rem !important; }
  .mb-xs-n3 { margin-bottom: -1rem !important; }
  .mb-xs-n4 { margin-bottom: -1.5rem !important; }
  .mb-xs-n5 { margin-bottom: -3rem !important; }
  .ml-xs-n1 { margin-left: -0.25rem !important; }
  .ml-xs-n2 { margin-left: -0.5rem !important; }
  .ml-xs-n3 { margin-left: -1rem !important; }
  .ml-xs-n4 { margin-left: -1.5rem !important; }
  .ml-xs-n5 { margin-left: -3rem !important; }
  .p-xs-0 { padding: 0 !important; }
  .p-xs-1 { padding: 0.25rem !important; }
  .p-xs-2 { padding: 0.5rem !important; }
  .p-xs-3 { padding: 1rem !important; }
  .p-xs-4 { padding: 1.5rem !important; }
  .p-xs-5 { padding: 3rem !important; }
  .px-xs-0 { padding-right: 0 !important; padding-left: 0 !important; }
  .px-xs-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
  .px-xs-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
  .px-xs-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
  .px-xs-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
  .px-xs-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
  .py-xs-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
  .py-xs-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
  .py-xs-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
  .py-xs-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
  .py-xs-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
  .py-xs-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
  .pt-xs-0 { padding-top: 0 !important; }
  .pt-xs-1 { padding-top: 0.25rem !important; }
  .pt-xs-2 { padding-top: 0.5rem !important; }
  .pt-xs-3 { padding-top: 1rem !important; }
  .pt-xs-4 { padding-top: 1.5rem !important; }
  .pt-xs-5 { padding-top: 3rem !important; }
  .pr-xs-0 { padding-right: 0 !important; }
  .pr-xs-1 { padding-right: 0.25rem !important; }
  .pr-xs-2 { padding-right: 0.5rem !important; }
  .pr-xs-3 { padding-right: 1rem !important; }
  .pr-xs-4 { padding-right: 1.5rem !important; }
  .pr-xs-5 { padding-right: 3rem !important; }
  .pb-xs-0 { padding-bottom: 0 !important; }
  .pb-xs-1 { padding-bottom: 0.25rem !important; }
  .pb-xs-2 { padding-bottom: 0.5rem !important; }
  .pb-xs-3 { padding-bottom: 1rem !important; }
  .pb-xs-4 { padding-bottom: 1.5rem !important; }
  .pb-xs-5 { padding-bottom: 3rem !important; }
  .pl-xs-0 { padding-left: 0 !important; }
  .pl-xs-1 { padding-left: 0.25rem !important; }
  .pl-xs-2 { padding-left: 0.5rem !important; }
  .pl-xs-3 { padding-left: 1rem !important; }
  .pl-xs-4 { padding-left: 1.5rem !important; }
  .pl-xs-5 { padding-left: 3rem !important; } }

@media (min-width: 576px) { .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; }
  .flex-sm-fill { flex: 1 1 auto !important; }
  .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-grow-0 { flex-grow: 0 !important; }
  .flex-sm-grow-1 { flex-grow: 1 !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; }
  .order-sm-first { order: -1 !important; }
  .order-sm-0 { order: 0 !important; }
  .order-sm-1 { order: 1 !important; }
  .order-sm-2 { order: 2 !important; }
  .order-sm-3 { order: 3 !important; }
  .order-sm-4 { order: 4 !important; }
  .order-sm-5 { order: 5 !important; }
  .order-sm-last { order: 6 !important; }
  .m-sm-0 { margin: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mx-sm-0 { margin-right: 0 !important; margin-left: 0 !important; }
  .mx-sm-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
  .mx-sm-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
  .mx-sm-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
  .mx-sm-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
  .mx-sm-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
  .mx-sm-auto { margin-right: auto !important; margin-left: auto !important; }
  .my-sm-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
  .my-sm-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
  .my-sm-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
  .my-sm-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
  .my-sm-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
  .my-sm-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
  .my-sm-auto { margin-top: auto !important; margin-bottom: auto !important; }
  .mt-sm-0 { margin-top: 0 !important; }
  .mt-sm-1 { margin-top: 0.25rem !important; }
  .mt-sm-2 { margin-top: 0.5rem !important; }
  .mt-sm-3 { margin-top: 1rem !important; }
  .mt-sm-4 { margin-top: 1.5rem !important; }
  .mt-sm-5 { margin-top: 3rem !important; }
  .mt-sm-auto { margin-top: auto !important; }
  .mr-sm-0 { margin-right: 0 !important; }
  .mr-sm-1 { margin-right: 0.25rem !important; }
  .mr-sm-2 { margin-right: 0.5rem !important; }
  .mr-sm-3 { margin-right: 1rem !important; }
  .mr-sm-4 { margin-right: 1.5rem !important; }
  .mr-sm-5 { margin-right: 3rem !important; }
  .mr-sm-auto { margin-right: auto !important; }
  .mb-sm-0 { margin-bottom: 0 !important; }
  .mb-sm-1 { margin-bottom: 0.25rem !important; }
  .mb-sm-2 { margin-bottom: 0.5rem !important; }
  .mb-sm-3 { margin-bottom: 1rem !important; }
  .mb-sm-4 { margin-bottom: 1.5rem !important; }
  .mb-sm-5 { margin-bottom: 3rem !important; }
  .mb-sm-auto { margin-bottom: auto !important; }
  .ml-sm-0 { margin-left: 0 !important; }
  .ml-sm-1 { margin-left: 0.25rem !important; }
  .ml-sm-2 { margin-left: 0.5rem !important; }
  .ml-sm-3 { margin-left: 1rem !important; }
  .ml-sm-4 { margin-left: 1.5rem !important; }
  .ml-sm-5 { margin-left: 3rem !important; }
  .ml-sm-auto { margin-left: auto !important; }
  .m-sm-n1 { margin: -0.25rem !important; }
  .m-sm-n2 { margin: -0.5rem !important; }
  .m-sm-n3 { margin: -1rem !important; }
  .m-sm-n4 { margin: -1.5rem !important; }
  .m-sm-n5 { margin: -3rem !important; }
  .mx-sm-n1 { margin-right: -0.25rem !important; margin-left: -0.25rem !important; }
  .mx-sm-n2 { margin-right: -0.5rem !important; margin-left: -0.5rem !important; }
  .mx-sm-n3 { margin-right: -1rem !important; margin-left: -1rem !important; }
  .mx-sm-n4 { margin-right: -1.5rem !important; margin-left: -1.5rem !important; }
  .mx-sm-n5 { margin-right: -3rem !important; margin-left: -3rem !important; }
  .my-sm-n1 { margin-top: -0.25rem !important; margin-bottom: -0.25rem !important; }
  .my-sm-n2 { margin-top: -0.5rem !important; margin-bottom: -0.5rem !important; }
  .my-sm-n3 { margin-top: -1rem !important; margin-bottom: -1rem !important; }
  .my-sm-n4 { margin-top: -1.5rem !important; margin-bottom: -1.5rem !important; }
  .my-sm-n5 { margin-top: -3rem !important; margin-bottom: -3rem !important; }
  .mt-sm-n1 { margin-top: -0.25rem !important; }
  .mt-sm-n2 { margin-top: -0.5rem !important; }
  .mt-sm-n3 { margin-top: -1rem !important; }
  .mt-sm-n4 { margin-top: -1.5rem !important; }
  .mt-sm-n5 { margin-top: -3rem !important; }
  .mr-sm-n1 { margin-right: -0.25rem !important; }
  .mr-sm-n2 { margin-right: -0.5rem !important; }
  .mr-sm-n3 { margin-right: -1rem !important; }
  .mr-sm-n4 { margin-right: -1.5rem !important; }
  .mr-sm-n5 { margin-right: -3rem !important; }
  .mb-sm-n1 { margin-bottom: -0.25rem !important; }
  .mb-sm-n2 { margin-bottom: -0.5rem !important; }
  .mb-sm-n3 { margin-bottom: -1rem !important; }
  .mb-sm-n4 { margin-bottom: -1.5rem !important; }
  .mb-sm-n5 { margin-bottom: -3rem !important; }
  .ml-sm-n1 { margin-left: -0.25rem !important; }
  .ml-sm-n2 { margin-left: -0.5rem !important; }
  .ml-sm-n3 { margin-left: -1rem !important; }
  .ml-sm-n4 { margin-left: -1.5rem !important; }
  .ml-sm-n5 { margin-left: -3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .px-sm-0 { padding-right: 0 !important; padding-left: 0 !important; }
  .px-sm-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
  .px-sm-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
  .px-sm-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
  .px-sm-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
  .px-sm-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
  .py-sm-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
  .py-sm-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
  .py-sm-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
  .py-sm-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
  .py-sm-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
  .py-sm-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
  .pt-sm-0 { padding-top: 0 !important; }
  .pt-sm-1 { padding-top: 0.25rem !important; }
  .pt-sm-2 { padding-top: 0.5rem !important; }
  .pt-sm-3 { padding-top: 1rem !important; }
  .pt-sm-4 { padding-top: 1.5rem !important; }
  .pt-sm-5 { padding-top: 3rem !important; }
  .pr-sm-0 { padding-right: 0 !important; }
  .pr-sm-1 { padding-right: 0.25rem !important; }
  .pr-sm-2 { padding-right: 0.5rem !important; }
  .pr-sm-3 { padding-right: 1rem !important; }
  .pr-sm-4 { padding-right: 1.5rem !important; }
  .pr-sm-5 { padding-right: 3rem !important; }
  .pb-sm-0 { padding-bottom: 0 !important; }
  .pb-sm-1 { padding-bottom: 0.25rem !important; }
  .pb-sm-2 { padding-bottom: 0.5rem !important; }
  .pb-sm-3 { padding-bottom: 1rem !important; }
  .pb-sm-4 { padding-bottom: 1.5rem !important; }
  .pb-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-0 { padding-left: 0 !important; }
  .pl-sm-1 { padding-left: 0.25rem !important; }
  .pl-sm-2 { padding-left: 0.5rem !important; }
  .pl-sm-3 { padding-left: 1rem !important; }
  .pl-sm-4 { padding-left: 1.5rem !important; }
  .pl-sm-5 { padding-left: 3rem !important; } }

@media (min-width: 768px) { .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; }
  .flex-md-fill { flex: 1 1 auto !important; }
  .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-grow-0 { flex-grow: 0 !important; }
  .flex-md-grow-1 { flex-grow: 1 !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; }
  .order-md-first { order: -1 !important; }
  .order-md-0 { order: 0 !important; }
  .order-md-1 { order: 1 !important; }
  .order-md-2 { order: 2 !important; }
  .order-md-3 { order: 3 !important; }
  .order-md-4 { order: 4 !important; }
  .order-md-5 { order: 5 !important; }
  .order-md-last { order: 6 !important; }
  .m-md-0 { margin: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mx-md-0 { margin-right: 0 !important; margin-left: 0 !important; }
  .mx-md-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
  .mx-md-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
  .mx-md-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
  .mx-md-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
  .mx-md-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
  .mx-md-auto { margin-right: auto !important; margin-left: auto !important; }
  .my-md-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
  .my-md-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
  .my-md-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
  .my-md-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
  .my-md-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
  .my-md-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
  .my-md-auto { margin-top: auto !important; margin-bottom: auto !important; }
  .mt-md-0 { margin-top: 0 !important; }
  .mt-md-1 { margin-top: 0.25rem !important; }
  .mt-md-2 { margin-top: 0.5rem !important; }
  .mt-md-3 { margin-top: 1rem !important; }
  .mt-md-4 { margin-top: 1.5rem !important; }
  .mt-md-5 { margin-top: 3rem !important; }
  .mt-md-auto { margin-top: auto !important; }
  .mr-md-0 { margin-right: 0 !important; }
  .mr-md-1 { margin-right: 0.25rem !important; }
  .mr-md-2 { margin-right: 0.5rem !important; }
  .mr-md-3 { margin-right: 1rem !important; }
  .mr-md-4 { margin-right: 1.5rem !important; }
  .mr-md-5 { margin-right: 3rem !important; }
  .mr-md-auto { margin-right: auto !important; }
  .mb-md-0 { margin-bottom: 0 !important; }
  .mb-md-1 { margin-bottom: 0.25rem !important; }
  .mb-md-2 { margin-bottom: 0.5rem !important; }
  .mb-md-3 { margin-bottom: 1rem !important; }
  .mb-md-4 { margin-bottom: 1.5rem !important; }
  .mb-md-5 { margin-bottom: 3rem !important; }
  .mb-md-auto { margin-bottom: auto !important; }
  .ml-md-0 { margin-left: 0 !important; }
  .ml-md-1 { margin-left: 0.25rem !important; }
  .ml-md-2 { margin-left: 0.5rem !important; }
  .ml-md-3 { margin-left: 1rem !important; }
  .ml-md-4 { margin-left: 1.5rem !important; }
  .ml-md-5 { margin-left: 3rem !important; }
  .ml-md-auto { margin-left: auto !important; }
  .m-md-n1 { margin: -0.25rem !important; }
  .m-md-n2 { margin: -0.5rem !important; }
  .m-md-n3 { margin: -1rem !important; }
  .m-md-n4 { margin: -1.5rem !important; }
  .m-md-n5 { margin: -3rem !important; }
  .mx-md-n1 { margin-right: -0.25rem !important; margin-left: -0.25rem !important; }
  .mx-md-n2 { margin-right: -0.5rem !important; margin-left: -0.5rem !important; }
  .mx-md-n3 { margin-right: -1rem !important; margin-left: -1rem !important; }
  .mx-md-n4 { margin-right: -1.5rem !important; margin-left: -1.5rem !important; }
  .mx-md-n5 { margin-right: -3rem !important; margin-left: -3rem !important; }
  .my-md-n1 { margin-top: -0.25rem !important; margin-bottom: -0.25rem !important; }
  .my-md-n2 { margin-top: -0.5rem !important; margin-bottom: -0.5rem !important; }
  .my-md-n3 { margin-top: -1rem !important; margin-bottom: -1rem !important; }
  .my-md-n4 { margin-top: -1.5rem !important; margin-bottom: -1.5rem !important; }
  .my-md-n5 { margin-top: -3rem !important; margin-bottom: -3rem !important; }
  .mt-md-n1 { margin-top: -0.25rem !important; }
  .mt-md-n2 { margin-top: -0.5rem !important; }
  .mt-md-n3 { margin-top: -1rem !important; }
  .mt-md-n4 { margin-top: -1.5rem !important; }
  .mt-md-n5 { margin-top: -3rem !important; }
  .mr-md-n1 { margin-right: -0.25rem !important; }
  .mr-md-n2 { margin-right: -0.5rem !important; }
  .mr-md-n3 { margin-right: -1rem !important; }
  .mr-md-n4 { margin-right: -1.5rem !important; }
  .mr-md-n5 { margin-right: -3rem !important; }
  .mb-md-n1 { margin-bottom: -0.25rem !important; }
  .mb-md-n2 { margin-bottom: -0.5rem !important; }
  .mb-md-n3 { margin-bottom: -1rem !important; }
  .mb-md-n4 { margin-bottom: -1.5rem !important; }
  .mb-md-n5 { margin-bottom: -3rem !important; }
  .ml-md-n1 { margin-left: -0.25rem !important; }
  .ml-md-n2 { margin-left: -0.5rem !important; }
  .ml-md-n3 { margin-left: -1rem !important; }
  .ml-md-n4 { margin-left: -1.5rem !important; }
  .ml-md-n5 { margin-left: -3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .px-md-0 { padding-right: 0 !important; padding-left: 0 !important; }
  .px-md-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
  .px-md-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
  .px-md-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
  .px-md-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
  .px-md-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
  .py-md-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
  .py-md-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
  .py-md-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
  .py-md-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
  .py-md-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
  .py-md-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
  .pt-md-0 { padding-top: 0 !important; }
  .pt-md-1 { padding-top: 0.25rem !important; }
  .pt-md-2 { padding-top: 0.5rem !important; }
  .pt-md-3 { padding-top: 1rem !important; }
  .pt-md-4 { padding-top: 1.5rem !important; }
  .pt-md-5 { padding-top: 3rem !important; }
  .pr-md-0 { padding-right: 0 !important; }
  .pr-md-1 { padding-right: 0.25rem !important; }
  .pr-md-2 { padding-right: 0.5rem !important; }
  .pr-md-3 { padding-right: 1rem !important; }
  .pr-md-4 { padding-right: 1.5rem !important; }
  .pr-md-5 { padding-right: 3rem !important; }
  .pb-md-0 { padding-bottom: 0 !important; }
  .pb-md-1 { padding-bottom: 0.25rem !important; }
  .pb-md-2 { padding-bottom: 0.5rem !important; }
  .pb-md-3 { padding-bottom: 1rem !important; }
  .pb-md-4 { padding-bottom: 1.5rem !important; }
  .pb-md-5 { padding-bottom: 3rem !important; }
  .pl-md-0 { padding-left: 0 !important; }
  .pl-md-1 { padding-left: 0.25rem !important; }
  .pl-md-2 { padding-left: 0.5rem !important; }
  .pl-md-3 { padding-left: 1rem !important; }
  .pl-md-4 { padding-left: 1.5rem !important; }
  .pl-md-5 { padding-left: 3rem !important; } }

@media (min-width: 992px) { .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; }
  .flex-lg-fill { flex: 1 1 auto !important; }
  .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-grow-0 { flex-grow: 0 !important; }
  .flex-lg-grow-1 { flex-grow: 1 !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; }
  .order-lg-first { order: -1 !important; }
  .order-lg-0 { order: 0 !important; }
  .order-lg-1 { order: 1 !important; }
  .order-lg-2 { order: 2 !important; }
  .order-lg-3 { order: 3 !important; }
  .order-lg-4 { order: 4 !important; }
  .order-lg-5 { order: 5 !important; }
  .order-lg-last { order: 6 !important; }
  .m-lg-0 { margin: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mx-lg-0 { margin-right: 0 !important; margin-left: 0 !important; }
  .mx-lg-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
  .mx-lg-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
  .mx-lg-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
  .mx-lg-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
  .mx-lg-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
  .mx-lg-auto { margin-right: auto !important; margin-left: auto !important; }
  .my-lg-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
  .my-lg-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
  .my-lg-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
  .my-lg-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
  .my-lg-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
  .my-lg-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
  .my-lg-auto { margin-top: auto !important; margin-bottom: auto !important; }
  .mt-lg-0 { margin-top: 0 !important; }
  .mt-lg-1 { margin-top: 0.25rem !important; }
  .mt-lg-2 { margin-top: 0.5rem !important; }
  .mt-lg-3 { margin-top: 1rem !important; }
  .mt-lg-4 { margin-top: 1.5rem !important; }
  .mt-lg-5 { margin-top: 3rem !important; }
  .mt-lg-auto { margin-top: auto !important; }
  .mr-lg-0 { margin-right: 0 !important; }
  .mr-lg-1 { margin-right: 0.25rem !important; }
  .mr-lg-2 { margin-right: 0.5rem !important; }
  .mr-lg-3 { margin-right: 1rem !important; }
  .mr-lg-4 { margin-right: 1.5rem !important; }
  .mr-lg-5 { margin-right: 3rem !important; }
  .mr-lg-auto { margin-right: auto !important; }
  .mb-lg-0 { margin-bottom: 0 !important; }
  .mb-lg-1 { margin-bottom: 0.25rem !important; }
  .mb-lg-2 { margin-bottom: 0.5rem !important; }
  .mb-lg-3 { margin-bottom: 1rem !important; }
  .mb-lg-4 { margin-bottom: 1.5rem !important; }
  .mb-lg-5 { margin-bottom: 3rem !important; }
  .mb-lg-auto { margin-bottom: auto !important; }
  .ml-lg-0 { margin-left: 0 !important; }
  .ml-lg-1 { margin-left: 0.25rem !important; }
  .ml-lg-2 { margin-left: 0.5rem !important; }
  .ml-lg-3 { margin-left: 1rem !important; }
  .ml-lg-4 { margin-left: 1.5rem !important; }
  .ml-lg-5 { margin-left: 3rem !important; }
  .ml-lg-auto { margin-left: auto !important; }
  .m-lg-n1 { margin: -0.25rem !important; }
  .m-lg-n2 { margin: -0.5rem !important; }
  .m-lg-n3 { margin: -1rem !important; }
  .m-lg-n4 { margin: -1.5rem !important; }
  .m-lg-n5 { margin: -3rem !important; }
  .mx-lg-n1 { margin-right: -0.25rem !important; margin-left: -0.25rem !important; }
  .mx-lg-n2 { margin-right: -0.5rem !important; margin-left: -0.5rem !important; }
  .mx-lg-n3 { margin-right: -1rem !important; margin-left: -1rem !important; }
  .mx-lg-n4 { margin-right: -1.5rem !important; margin-left: -1.5rem !important; }
  .mx-lg-n5 { margin-right: -3rem !important; margin-left: -3rem !important; }
  .my-lg-n1 { margin-top: -0.25rem !important; margin-bottom: -0.25rem !important; }
  .my-lg-n2 { margin-top: -0.5rem !important; margin-bottom: -0.5rem !important; }
  .my-lg-n3 { margin-top: -1rem !important; margin-bottom: -1rem !important; }
  .my-lg-n4 { margin-top: -1.5rem !important; margin-bottom: -1.5rem !important; }
  .my-lg-n5 { margin-top: -3rem !important; margin-bottom: -3rem !important; }
  .mt-lg-n1 { margin-top: -0.25rem !important; }
  .mt-lg-n2 { margin-top: -0.5rem !important; }
  .mt-lg-n3 { margin-top: -1rem !important; }
  .mt-lg-n4 { margin-top: -1.5rem !important; }
  .mt-lg-n5 { margin-top: -3rem !important; }
  .mr-lg-n1 { margin-right: -0.25rem !important; }
  .mr-lg-n2 { margin-right: -0.5rem !important; }
  .mr-lg-n3 { margin-right: -1rem !important; }
  .mr-lg-n4 { margin-right: -1.5rem !important; }
  .mr-lg-n5 { margin-right: -3rem !important; }
  .mb-lg-n1 { margin-bottom: -0.25rem !important; }
  .mb-lg-n2 { margin-bottom: -0.5rem !important; }
  .mb-lg-n3 { margin-bottom: -1rem !important; }
  .mb-lg-n4 { margin-bottom: -1.5rem !important; }
  .mb-lg-n5 { margin-bottom: -3rem !important; }
  .ml-lg-n1 { margin-left: -0.25rem !important; }
  .ml-lg-n2 { margin-left: -0.5rem !important; }
  .ml-lg-n3 { margin-left: -1rem !important; }
  .ml-lg-n4 { margin-left: -1.5rem !important; }
  .ml-lg-n5 { margin-left: -3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .px-lg-0 { padding-right: 0 !important; padding-left: 0 !important; }
  .px-lg-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
  .px-lg-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
  .px-lg-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
  .px-lg-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
  .px-lg-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
  .py-lg-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
  .py-lg-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
  .py-lg-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
  .py-lg-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
  .py-lg-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
  .py-lg-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
  .pt-lg-0 { padding-top: 0 !important; }
  .pt-lg-1 { padding-top: 0.25rem !important; }
  .pt-lg-2 { padding-top: 0.5rem !important; }
  .pt-lg-3 { padding-top: 1rem !important; }
  .pt-lg-4 { padding-top: 1.5rem !important; }
  .pt-lg-5 { padding-top: 3rem !important; }
  .pr-lg-0 { padding-right: 0 !important; }
  .pr-lg-1 { padding-right: 0.25rem !important; }
  .pr-lg-2 { padding-right: 0.5rem !important; }
  .pr-lg-3 { padding-right: 1rem !important; }
  .pr-lg-4 { padding-right: 1.5rem !important; }
  .pr-lg-5 { padding-right: 3rem !important; }
  .pb-lg-0 { padding-bottom: 0 !important; }
  .pb-lg-1 { padding-bottom: 0.25rem !important; }
  .pb-lg-2 { padding-bottom: 0.5rem !important; }
  .pb-lg-3 { padding-bottom: 1rem !important; }
  .pb-lg-4 { padding-bottom: 1.5rem !important; }
  .pb-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-0 { padding-left: 0 !important; }
  .pl-lg-1 { padding-left: 0.25rem !important; }
  .pl-lg-2 { padding-left: 0.5rem !important; }
  .pl-lg-3 { padding-left: 1rem !important; }
  .pl-lg-4 { padding-left: 1.5rem !important; }
  .pl-lg-5 { padding-left: 3rem !important; } }

@media (min-width: 1200px) { .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; }
  .flex-xl-fill { flex: 1 1 auto !important; }
  .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-grow-0 { flex-grow: 0 !important; }
  .flex-xl-grow-1 { flex-grow: 1 !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; }
  .order-xl-first { order: -1 !important; }
  .order-xl-0 { order: 0 !important; }
  .order-xl-1 { order: 1 !important; }
  .order-xl-2 { order: 2 !important; }
  .order-xl-3 { order: 3 !important; }
  .order-xl-4 { order: 4 !important; }
  .order-xl-5 { order: 5 !important; }
  .order-xl-last { order: 6 !important; }
  .m-xl-0 { margin: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mx-xl-0 { margin-right: 0 !important; margin-left: 0 !important; }
  .mx-xl-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
  .mx-xl-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
  .mx-xl-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
  .mx-xl-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
  .mx-xl-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
  .mx-xl-auto { margin-right: auto !important; margin-left: auto !important; }
  .my-xl-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
  .my-xl-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
  .my-xl-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
  .my-xl-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
  .my-xl-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
  .my-xl-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
  .my-xl-auto { margin-top: auto !important; margin-bottom: auto !important; }
  .mt-xl-0 { margin-top: 0 !important; }
  .mt-xl-1 { margin-top: 0.25rem !important; }
  .mt-xl-2 { margin-top: 0.5rem !important; }
  .mt-xl-3 { margin-top: 1rem !important; }
  .mt-xl-4 { margin-top: 1.5rem !important; }
  .mt-xl-5 { margin-top: 3rem !important; }
  .mt-xl-auto { margin-top: auto !important; }
  .mr-xl-0 { margin-right: 0 !important; }
  .mr-xl-1 { margin-right: 0.25rem !important; }
  .mr-xl-2 { margin-right: 0.5rem !important; }
  .mr-xl-3 { margin-right: 1rem !important; }
  .mr-xl-4 { margin-right: 1.5rem !important; }
  .mr-xl-5 { margin-right: 3rem !important; }
  .mr-xl-auto { margin-right: auto !important; }
  .mb-xl-0 { margin-bottom: 0 !important; }
  .mb-xl-1 { margin-bottom: 0.25rem !important; }
  .mb-xl-2 { margin-bottom: 0.5rem !important; }
  .mb-xl-3 { margin-bottom: 1rem !important; }
  .mb-xl-4 { margin-bottom: 1.5rem !important; }
  .mb-xl-5 { margin-bottom: 3rem !important; }
  .mb-xl-auto { margin-bottom: auto !important; }
  .ml-xl-0 { margin-left: 0 !important; }
  .ml-xl-1 { margin-left: 0.25rem !important; }
  .ml-xl-2 { margin-left: 0.5rem !important; }
  .ml-xl-3 { margin-left: 1rem !important; }
  .ml-xl-4 { margin-left: 1.5rem !important; }
  .ml-xl-5 { margin-left: 3rem !important; }
  .ml-xl-auto { margin-left: auto !important; }
  .m-xl-n1 { margin: -0.25rem !important; }
  .m-xl-n2 { margin: -0.5rem !important; }
  .m-xl-n3 { margin: -1rem !important; }
  .m-xl-n4 { margin: -1.5rem !important; }
  .m-xl-n5 { margin: -3rem !important; }
  .mx-xl-n1 { margin-right: -0.25rem !important; margin-left: -0.25rem !important; }
  .mx-xl-n2 { margin-right: -0.5rem !important; margin-left: -0.5rem !important; }
  .mx-xl-n3 { margin-right: -1rem !important; margin-left: -1rem !important; }
  .mx-xl-n4 { margin-right: -1.5rem !important; margin-left: -1.5rem !important; }
  .mx-xl-n5 { margin-right: -3rem !important; margin-left: -3rem !important; }
  .my-xl-n1 { margin-top: -0.25rem !important; margin-bottom: -0.25rem !important; }
  .my-xl-n2 { margin-top: -0.5rem !important; margin-bottom: -0.5rem !important; }
  .my-xl-n3 { margin-top: -1rem !important; margin-bottom: -1rem !important; }
  .my-xl-n4 { margin-top: -1.5rem !important; margin-bottom: -1.5rem !important; }
  .my-xl-n5 { margin-top: -3rem !important; margin-bottom: -3rem !important; }
  .mt-xl-n1 { margin-top: -0.25rem !important; }
  .mt-xl-n2 { margin-top: -0.5rem !important; }
  .mt-xl-n3 { margin-top: -1rem !important; }
  .mt-xl-n4 { margin-top: -1.5rem !important; }
  .mt-xl-n5 { margin-top: -3rem !important; }
  .mr-xl-n1 { margin-right: -0.25rem !important; }
  .mr-xl-n2 { margin-right: -0.5rem !important; }
  .mr-xl-n3 { margin-right: -1rem !important; }
  .mr-xl-n4 { margin-right: -1.5rem !important; }
  .mr-xl-n5 { margin-right: -3rem !important; }
  .mb-xl-n1 { margin-bottom: -0.25rem !important; }
  .mb-xl-n2 { margin-bottom: -0.5rem !important; }
  .mb-xl-n3 { margin-bottom: -1rem !important; }
  .mb-xl-n4 { margin-bottom: -1.5rem !important; }
  .mb-xl-n5 { margin-bottom: -3rem !important; }
  .ml-xl-n1 { margin-left: -0.25rem !important; }
  .ml-xl-n2 { margin-left: -0.5rem !important; }
  .ml-xl-n3 { margin-left: -1rem !important; }
  .ml-xl-n4 { margin-left: -1.5rem !important; }
  .ml-xl-n5 { margin-left: -3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .px-xl-0 { padding-right: 0 !important; padding-left: 0 !important; }
  .px-xl-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
  .px-xl-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
  .px-xl-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
  .px-xl-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
  .px-xl-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
  .py-xl-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
  .py-xl-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
  .py-xl-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
  .py-xl-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
  .py-xl-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
  .py-xl-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
  .pt-xl-0 { padding-top: 0 !important; }
  .pt-xl-1 { padding-top: 0.25rem !important; }
  .pt-xl-2 { padding-top: 0.5rem !important; }
  .pt-xl-3 { padding-top: 1rem !important; }
  .pt-xl-4 { padding-top: 1.5rem !important; }
  .pt-xl-5 { padding-top: 3rem !important; }
  .pr-xl-0 { padding-right: 0 !important; }
  .pr-xl-1 { padding-right: 0.25rem !important; }
  .pr-xl-2 { padding-right: 0.5rem !important; }
  .pr-xl-3 { padding-right: 1rem !important; }
  .pr-xl-4 { padding-right: 1.5rem !important; }
  .pr-xl-5 { padding-right: 3rem !important; }
  .pb-xl-0 { padding-bottom: 0 !important; }
  .pb-xl-1 { padding-bottom: 0.25rem !important; }
  .pb-xl-2 { padding-bottom: 0.5rem !important; }
  .pb-xl-3 { padding-bottom: 1rem !important; }
  .pb-xl-4 { padding-bottom: 1.5rem !important; }
  .pb-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-0 { padding-left: 0 !important; }
  .pl-xl-1 { padding-left: 0.25rem !important; }
  .pl-xl-2 { padding-left: 0.5rem !important; }
  .pl-xl-3 { padding-left: 1rem !important; }
  .pl-xl-4 { padding-left: 1.5rem !important; }
  .pl-xl-5 { padding-left: 3rem !important; } }

@media (min-width: 1300px) { .d-xxl-none { display: none !important; }
  .d-xxl-inline { display: inline !important; }
  .d-xxl-inline-block { display: inline-block !important; }
  .d-xxl-block { display: block !important; }
  .d-xxl-table { display: table !important; }
  .d-xxl-table-row { display: table-row !important; }
  .d-xxl-table-cell { display: table-cell !important; }
  .d-xxl-flex { display: flex !important; }
  .d-xxl-inline-flex { display: inline-flex !important; }
  .flex-xxl-fill { flex: 1 1 auto !important; }
  .flex-xxl-row { flex-direction: row !important; }
  .flex-xxl-column { flex-direction: column !important; }
  .flex-xxl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 { flex-grow: 0 !important; }
  .flex-xxl-grow-1 { flex-grow: 1 !important; }
  .justify-content-xxl-start { justify-content: flex-start !important; }
  .justify-content-xxl-end { justify-content: flex-end !important; }
  .justify-content-xxl-center { justify-content: center !important; }
  .justify-content-xxl-between { justify-content: space-between !important; }
  .justify-content-xxl-around { justify-content: space-around !important; }
  .align-items-xxl-start { align-items: flex-start !important; }
  .align-items-xxl-end { align-items: flex-end !important; }
  .align-items-xxl-center { align-items: center !important; }
  .align-items-xxl-baseline { align-items: baseline !important; }
  .align-items-xxl-stretch { align-items: stretch !important; }
  .align-content-xxl-start { align-content: flex-start !important; }
  .align-content-xxl-end { align-content: flex-end !important; }
  .align-content-xxl-center { align-content: center !important; }
  .align-content-xxl-between { align-content: space-between !important; }
  .align-content-xxl-around { align-content: space-around !important; }
  .align-content-xxl-stretch { align-content: stretch !important; }
  .align-self-xxl-auto { align-self: auto !important; }
  .align-self-xxl-start { align-self: flex-start !important; }
  .align-self-xxl-end { align-self: flex-end !important; }
  .align-self-xxl-center { align-self: center !important; }
  .align-self-xxl-baseline { align-self: baseline !important; }
  .align-self-xxl-stretch { align-self: stretch !important; }
  .order-xxl-first { order: -1 !important; }
  .order-xxl-0 { order: 0 !important; }
  .order-xxl-1 { order: 1 !important; }
  .order-xxl-2 { order: 2 !important; }
  .order-xxl-3 { order: 3 !important; }
  .order-xxl-4 { order: 4 !important; }
  .order-xxl-5 { order: 5 !important; }
  .order-xxl-last { order: 6 !important; }
  .m-xxl-0 { margin: 0 !important; }
  .m-xxl-1 { margin: 0.25rem !important; }
  .m-xxl-2 { margin: 0.5rem !important; }
  .m-xxl-3 { margin: 1rem !important; }
  .m-xxl-4 { margin: 1.5rem !important; }
  .m-xxl-5 { margin: 3rem !important; }
  .m-xxl-auto { margin: auto !important; }
  .mx-xxl-0 { margin-right: 0 !important; margin-left: 0 !important; }
  .mx-xxl-1 { margin-right: 0.25rem !important; margin-left: 0.25rem !important; }
  .mx-xxl-2 { margin-right: 0.5rem !important; margin-left: 0.5rem !important; }
  .mx-xxl-3 { margin-right: 1rem !important; margin-left: 1rem !important; }
  .mx-xxl-4 { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
  .mx-xxl-5 { margin-right: 3rem !important; margin-left: 3rem !important; }
  .mx-xxl-auto { margin-right: auto !important; margin-left: auto !important; }
  .my-xxl-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
  .my-xxl-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
  .my-xxl-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
  .my-xxl-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
  .my-xxl-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
  .my-xxl-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
  .my-xxl-auto { margin-top: auto !important; margin-bottom: auto !important; }
  .mt-xxl-0 { margin-top: 0 !important; }
  .mt-xxl-1 { margin-top: 0.25rem !important; }
  .mt-xxl-2 { margin-top: 0.5rem !important; }
  .mt-xxl-3 { margin-top: 1rem !important; }
  .mt-xxl-4 { margin-top: 1.5rem !important; }
  .mt-xxl-5 { margin-top: 3rem !important; }
  .mt-xxl-auto { margin-top: auto !important; }
  .mr-xxl-0 { margin-right: 0 !important; }
  .mr-xxl-1 { margin-right: 0.25rem !important; }
  .mr-xxl-2 { margin-right: 0.5rem !important; }
  .mr-xxl-3 { margin-right: 1rem !important; }
  .mr-xxl-4 { margin-right: 1.5rem !important; }
  .mr-xxl-5 { margin-right: 3rem !important; }
  .mr-xxl-auto { margin-right: auto !important; }
  .mb-xxl-0 { margin-bottom: 0 !important; }
  .mb-xxl-1 { margin-bottom: 0.25rem !important; }
  .mb-xxl-2 { margin-bottom: 0.5rem !important; }
  .mb-xxl-3 { margin-bottom: 1rem !important; }
  .mb-xxl-4 { margin-bottom: 1.5rem !important; }
  .mb-xxl-5 { margin-bottom: 3rem !important; }
  .mb-xxl-auto { margin-bottom: auto !important; }
  .ml-xxl-0 { margin-left: 0 !important; }
  .ml-xxl-1 { margin-left: 0.25rem !important; }
  .ml-xxl-2 { margin-left: 0.5rem !important; }
  .ml-xxl-3 { margin-left: 1rem !important; }
  .ml-xxl-4 { margin-left: 1.5rem !important; }
  .ml-xxl-5 { margin-left: 3rem !important; }
  .ml-xxl-auto { margin-left: auto !important; }
  .m-xxl-n1 { margin: -0.25rem !important; }
  .m-xxl-n2 { margin: -0.5rem !important; }
  .m-xxl-n3 { margin: -1rem !important; }
  .m-xxl-n4 { margin: -1.5rem !important; }
  .m-xxl-n5 { margin: -3rem !important; }
  .mx-xxl-n1 { margin-right: -0.25rem !important; margin-left: -0.25rem !important; }
  .mx-xxl-n2 { margin-right: -0.5rem !important; margin-left: -0.5rem !important; }
  .mx-xxl-n3 { margin-right: -1rem !important; margin-left: -1rem !important; }
  .mx-xxl-n4 { margin-right: -1.5rem !important; margin-left: -1.5rem !important; }
  .mx-xxl-n5 { margin-right: -3rem !important; margin-left: -3rem !important; }
  .my-xxl-n1 { margin-top: -0.25rem !important; margin-bottom: -0.25rem !important; }
  .my-xxl-n2 { margin-top: -0.5rem !important; margin-bottom: -0.5rem !important; }
  .my-xxl-n3 { margin-top: -1rem !important; margin-bottom: -1rem !important; }
  .my-xxl-n4 { margin-top: -1.5rem !important; margin-bottom: -1.5rem !important; }
  .my-xxl-n5 { margin-top: -3rem !important; margin-bottom: -3rem !important; }
  .mt-xxl-n1 { margin-top: -0.25rem !important; }
  .mt-xxl-n2 { margin-top: -0.5rem !important; }
  .mt-xxl-n3 { margin-top: -1rem !important; }
  .mt-xxl-n4 { margin-top: -1.5rem !important; }
  .mt-xxl-n5 { margin-top: -3rem !important; }
  .mr-xxl-n1 { margin-right: -0.25rem !important; }
  .mr-xxl-n2 { margin-right: -0.5rem !important; }
  .mr-xxl-n3 { margin-right: -1rem !important; }
  .mr-xxl-n4 { margin-right: -1.5rem !important; }
  .mr-xxl-n5 { margin-right: -3rem !important; }
  .mb-xxl-n1 { margin-bottom: -0.25rem !important; }
  .mb-xxl-n2 { margin-bottom: -0.5rem !important; }
  .mb-xxl-n3 { margin-bottom: -1rem !important; }
  .mb-xxl-n4 { margin-bottom: -1.5rem !important; }
  .mb-xxl-n5 { margin-bottom: -3rem !important; }
  .ml-xxl-n1 { margin-left: -0.25rem !important; }
  .ml-xxl-n2 { margin-left: -0.5rem !important; }
  .ml-xxl-n3 { margin-left: -1rem !important; }
  .ml-xxl-n4 { margin-left: -1.5rem !important; }
  .ml-xxl-n5 { margin-left: -3rem !important; }
  .p-xxl-0 { padding: 0 !important; }
  .p-xxl-1 { padding: 0.25rem !important; }
  .p-xxl-2 { padding: 0.5rem !important; }
  .p-xxl-3 { padding: 1rem !important; }
  .p-xxl-4 { padding: 1.5rem !important; }
  .p-xxl-5 { padding: 3rem !important; }
  .px-xxl-0 { padding-right: 0 !important; padding-left: 0 !important; }
  .px-xxl-1 { padding-right: 0.25rem !important; padding-left: 0.25rem !important; }
  .px-xxl-2 { padding-right: 0.5rem !important; padding-left: 0.5rem !important; }
  .px-xxl-3 { padding-right: 1rem !important; padding-left: 1rem !important; }
  .px-xxl-4 { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
  .px-xxl-5 { padding-right: 3rem !important; padding-left: 3rem !important; }
  .py-xxl-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
  .py-xxl-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
  .py-xxl-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
  .py-xxl-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
  .py-xxl-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
  .py-xxl-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
  .pt-xxl-0 { padding-top: 0 !important; }
  .pt-xxl-1 { padding-top: 0.25rem !important; }
  .pt-xxl-2 { padding-top: 0.5rem !important; }
  .pt-xxl-3 { padding-top: 1rem !important; }
  .pt-xxl-4 { padding-top: 1.5rem !important; }
  .pt-xxl-5 { padding-top: 3rem !important; }
  .pr-xxl-0 { padding-right: 0 !important; }
  .pr-xxl-1 { padding-right: 0.25rem !important; }
  .pr-xxl-2 { padding-right: 0.5rem !important; }
  .pr-xxl-3 { padding-right: 1rem !important; }
  .pr-xxl-4 { padding-right: 1.5rem !important; }
  .pr-xxl-5 { padding-right: 3rem !important; }
  .pb-xxl-0 { padding-bottom: 0 !important; }
  .pb-xxl-1 { padding-bottom: 0.25rem !important; }
  .pb-xxl-2 { padding-bottom: 0.5rem !important; }
  .pb-xxl-3 { padding-bottom: 1rem !important; }
  .pb-xxl-4 { padding-bottom: 1.5rem !important; }
  .pb-xxl-5 { padding-bottom: 3rem !important; }
  .pl-xxl-0 { padding-left: 0 !important; }
  .pl-xxl-1 { padding-left: 0.25rem !important; }
  .pl-xxl-2 { padding-left: 0.5rem !important; }
  .pl-xxl-3 { padding-left: 1rem !important; }
  .pl-xxl-4 { padding-left: 1.5rem !important; }
  .pl-xxl-5 { padding-left: 3rem !important; } }

@media print { .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: flex !important; }
  .d-print-inline-flex { display: inline-flex !important; } }

.m-0 { margin: 0 !important; }

.mt-0, .my-0 { margin-top: 0 !important; }

.mr-0, .mx-0 { margin-right: 0 !important; }

.mb-0, .my-0 { margin-bottom: 0 !important; }

.ml-0, .mx-0 { margin-left: 0 !important; }

.m-1 { margin: 0.25rem !important; }

.mt-1, .my-1 { margin-top: 0.25rem !important; }

.mr-1, .mx-1 { margin-right: 0.25rem !important; }

.mb-1, .my-1 { margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 { margin-left: 0.25rem !important; }

.m-2 { margin: 0.5rem !important; }

.mt-2, .my-2 { margin-top: 0.5rem !important; }

.mr-2, .mx-2 { margin-right: 0.5rem !important; }

.mb-2, .my-2 { margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 { margin-left: 0.5rem !important; }

.m-3 { margin: 1rem !important; }

.mt-3, .my-3 { margin-top: 1rem !important; }

.mr-3, .mx-3 { margin-right: 1rem !important; }

.mb-3, .my-3 { margin-bottom: 1rem !important; }

.ml-3, .mx-3 { margin-left: 1rem !important; }

.m-4 { margin: 1.5rem !important; }

.mt-4, .my-4 { margin-top: 1.5rem !important; }

.mr-4, .mx-4 { margin-right: 1.5rem !important; }

.mb-4, .my-4 { margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 { margin-left: 1.5rem !important; }

.m-5 { margin: 3rem !important; }

.mt-5, .my-5 { margin-top: 3rem !important; }

.mr-5, .mx-5 { margin-right: 3rem !important; }

.mb-5, .my-5 { margin-bottom: 3rem !important; }

.ml-5, .mx-5 { margin-left: 3rem !important; }

.p-0 { padding: 0 !important; }

.pt-0, .py-0 { padding-top: 0 !important; }

.pr-0, .px-0 { padding-right: 0 !important; }

.pb-0, .py-0 { padding-bottom: 0 !important; }

.pl-0, .px-0 { padding-left: 0 !important; }

.p-1 { padding: 0.25rem !important; }

.pt-1, .py-1 { padding-top: 0.25rem !important; }

.pr-1, .px-1 { padding-right: 0.25rem !important; }

.pb-1, .py-1 { padding-bottom: 0.25rem !important; }

.pl-1, .px-1 { padding-left: 0.25rem !important; }

.p-2 { padding: 0.5rem !important; }

.pt-2, .py-2 { padding-top: 0.5rem !important; }

.pr-2, .px-2 { padding-right: 0.5rem !important; }

.pb-2, .py-2 { padding-bottom: 0.5rem !important; }

.pl-2, .px-2 { padding-left: 0.5rem !important; }

.p-3 { padding: 1rem !important; }

.pt-3, .py-3 { padding-top: 1rem !important; }

.pr-3, .px-3 { padding-right: 1rem !important; }

.pb-3, .py-3 { padding-bottom: 1rem !important; }

.pl-3, .px-3 { padding-left: 1rem !important; }

.p-4 { padding: 1.5rem !important; }

.pt-4, .py-4 { padding-top: 1.5rem !important; }

.pr-4, .px-4 { padding-right: 1.5rem !important; }

.pb-4, .py-4 { padding-bottom: 1.5rem !important; }

.pl-4, .px-4 { padding-left: 1.5rem !important; }

.p-5 { padding: 3rem !important; }

.pt-5, .py-5 { padding-top: 3rem !important; }

.pr-5, .px-5 { padding-right: 3rem !important; }

.pb-5, .py-5 { padding-bottom: 3rem !important; }

.pl-5, .px-5 { padding-left: 3rem !important; }

.m-n1 { margin: -0.25rem !important; }

.mt-n1, .my-n1 { margin-top: -0.25rem !important; }

.mr-n1, .mx-n1 { margin-right: -0.25rem !important; }

.mb-n1, .my-n1 { margin-bottom: -0.25rem !important; }

.ml-n1, .mx-n1 { margin-left: -0.25rem !important; }

.m-n2 { margin: -0.5rem !important; }

.mt-n2, .my-n2 { margin-top: -0.5rem !important; }

.mr-n2, .mx-n2 { margin-right: -0.5rem !important; }

.mb-n2, .my-n2 { margin-bottom: -0.5rem !important; }

.ml-n2, .mx-n2 { margin-left: -0.5rem !important; }

.m-n3 { margin: -1rem !important; }

.mt-n3, .my-n3 { margin-top: -1rem !important; }

.mr-n3, .mx-n3 { margin-right: -1rem !important; }

.mb-n3, .my-n3 { margin-bottom: -1rem !important; }

.ml-n3, .mx-n3 { margin-left: -1rem !important; }

.m-n4 { margin: -1.5rem !important; }

.mt-n4, .my-n4 { margin-top: -1.5rem !important; }

.mr-n4, .mx-n4 { margin-right: -1.5rem !important; }

.mb-n4, .my-n4 { margin-bottom: -1.5rem !important; }

.ml-n4, .mx-n4 { margin-left: -1.5rem !important; }

.m-n5 { margin: -3rem !important; }

.mt-n5, .my-n5 { margin-top: -3rem !important; }

.mr-n5, .mx-n5 { margin-right: -3rem !important; }

.mb-n5, .my-n5 { margin-bottom: -3rem !important; }

.ml-n5, .mx-n5 { margin-left: -3rem !important; }

.m-auto { margin: auto !important; }

.mt-auto, .my-auto { margin-top: auto !important; }

.mr-auto, .mx-auto { margin-right: auto !important; }

.mb-auto, .my-auto { margin-bottom: auto !important; }

.ml-auto, .mx-auto { margin-left: auto !important; }

@media (min-width: 410px) { .m-xs-0 { margin: 0 !important; }
  .mt-xs-0, .my-xs-0 { margin-top: 0 !important; }
  .mr-xs-0, .mx-xs-0 { margin-right: 0 !important; }
  .mb-xs-0, .my-xs-0 { margin-bottom: 0 !important; }
  .ml-xs-0, .mx-xs-0 { margin-left: 0 !important; }
  .m-xs-1 { margin: 0.25rem !important; }
  .mt-xs-1, .my-xs-1 { margin-top: 0.25rem !important; }
  .mr-xs-1, .mx-xs-1 { margin-right: 0.25rem !important; }
  .mb-xs-1, .my-xs-1 { margin-bottom: 0.25rem !important; }
  .ml-xs-1, .mx-xs-1 { margin-left: 0.25rem !important; }
  .m-xs-2 { margin: 0.5rem !important; }
  .mt-xs-2, .my-xs-2 { margin-top: 0.5rem !important; }
  .mr-xs-2, .mx-xs-2 { margin-right: 0.5rem !important; }
  .mb-xs-2, .my-xs-2 { margin-bottom: 0.5rem !important; }
  .ml-xs-2, .mx-xs-2 { margin-left: 0.5rem !important; }
  .m-xs-3 { margin: 1rem !important; }
  .mt-xs-3, .my-xs-3 { margin-top: 1rem !important; }
  .mr-xs-3, .mx-xs-3 { margin-right: 1rem !important; }
  .mb-xs-3, .my-xs-3 { margin-bottom: 1rem !important; }
  .ml-xs-3, .mx-xs-3 { margin-left: 1rem !important; }
  .m-xs-4 { margin: 1.5rem !important; }
  .mt-xs-4, .my-xs-4 { margin-top: 1.5rem !important; }
  .mr-xs-4, .mx-xs-4 { margin-right: 1.5rem !important; }
  .mb-xs-4, .my-xs-4 { margin-bottom: 1.5rem !important; }
  .ml-xs-4, .mx-xs-4 { margin-left: 1.5rem !important; }
  .m-xs-5 { margin: 3rem !important; }
  .mt-xs-5, .my-xs-5 { margin-top: 3rem !important; }
  .mr-xs-5, .mx-xs-5 { margin-right: 3rem !important; }
  .mb-xs-5, .my-xs-5 { margin-bottom: 3rem !important; }
  .ml-xs-5, .mx-xs-5 { margin-left: 3rem !important; }
  .p-xs-0 { padding: 0 !important; }
  .pt-xs-0, .py-xs-0 { padding-top: 0 !important; }
  .pr-xs-0, .px-xs-0 { padding-right: 0 !important; }
  .pb-xs-0, .py-xs-0 { padding-bottom: 0 !important; }
  .pl-xs-0, .px-xs-0 { padding-left: 0 !important; }
  .p-xs-1 { padding: 0.25rem !important; }
  .pt-xs-1, .py-xs-1 { padding-top: 0.25rem !important; }
  .pr-xs-1, .px-xs-1 { padding-right: 0.25rem !important; }
  .pb-xs-1, .py-xs-1 { padding-bottom: 0.25rem !important; }
  .pl-xs-1, .px-xs-1 { padding-left: 0.25rem !important; }
  .p-xs-2 { padding: 0.5rem !important; }
  .pt-xs-2, .py-xs-2 { padding-top: 0.5rem !important; }
  .pr-xs-2, .px-xs-2 { padding-right: 0.5rem !important; }
  .pb-xs-2, .py-xs-2 { padding-bottom: 0.5rem !important; }
  .pl-xs-2, .px-xs-2 { padding-left: 0.5rem !important; }
  .p-xs-3 { padding: 1rem !important; }
  .pt-xs-3, .py-xs-3 { padding-top: 1rem !important; }
  .pr-xs-3, .px-xs-3 { padding-right: 1rem !important; }
  .pb-xs-3, .py-xs-3 { padding-bottom: 1rem !important; }
  .pl-xs-3, .px-xs-3 { padding-left: 1rem !important; }
  .p-xs-4 { padding: 1.5rem !important; }
  .pt-xs-4, .py-xs-4 { padding-top: 1.5rem !important; }
  .pr-xs-4, .px-xs-4 { padding-right: 1.5rem !important; }
  .pb-xs-4, .py-xs-4 { padding-bottom: 1.5rem !important; }
  .pl-xs-4, .px-xs-4 { padding-left: 1.5rem !important; }
  .p-xs-5 { padding: 3rem !important; }
  .pt-xs-5, .py-xs-5 { padding-top: 3rem !important; }
  .pr-xs-5, .px-xs-5 { padding-right: 3rem !important; }
  .pb-xs-5, .py-xs-5 { padding-bottom: 3rem !important; }
  .pl-xs-5, .px-xs-5 { padding-left: 3rem !important; }
  .m-xs-n1 { margin: -0.25rem !important; }
  .mt-xs-n1, .my-xs-n1 { margin-top: -0.25rem !important; }
  .mr-xs-n1, .mx-xs-n1 { margin-right: -0.25rem !important; }
  .mb-xs-n1, .my-xs-n1 { margin-bottom: -0.25rem !important; }
  .ml-xs-n1, .mx-xs-n1 { margin-left: -0.25rem !important; }
  .m-xs-n2 { margin: -0.5rem !important; }
  .mt-xs-n2, .my-xs-n2 { margin-top: -0.5rem !important; }
  .mr-xs-n2, .mx-xs-n2 { margin-right: -0.5rem !important; }
  .mb-xs-n2, .my-xs-n2 { margin-bottom: -0.5rem !important; }
  .ml-xs-n2, .mx-xs-n2 { margin-left: -0.5rem !important; }
  .m-xs-n3 { margin: -1rem !important; }
  .mt-xs-n3, .my-xs-n3 { margin-top: -1rem !important; }
  .mr-xs-n3, .mx-xs-n3 { margin-right: -1rem !important; }
  .mb-xs-n3, .my-xs-n3 { margin-bottom: -1rem !important; }
  .ml-xs-n3, .mx-xs-n3 { margin-left: -1rem !important; }
  .m-xs-n4 { margin: -1.5rem !important; }
  .mt-xs-n4, .my-xs-n4 { margin-top: -1.5rem !important; }
  .mr-xs-n4, .mx-xs-n4 { margin-right: -1.5rem !important; }
  .mb-xs-n4, .my-xs-n4 { margin-bottom: -1.5rem !important; }
  .ml-xs-n4, .mx-xs-n4 { margin-left: -1.5rem !important; }
  .m-xs-n5 { margin: -3rem !important; }
  .mt-xs-n5, .my-xs-n5 { margin-top: -3rem !important; }
  .mr-xs-n5, .mx-xs-n5 { margin-right: -3rem !important; }
  .mb-xs-n5, .my-xs-n5 { margin-bottom: -3rem !important; }
  .ml-xs-n5, .mx-xs-n5 { margin-left: -3rem !important; }
  .m-xs-auto { margin: auto !important; }
  .mt-xs-auto, .my-xs-auto { margin-top: auto !important; }
  .mr-xs-auto, .mx-xs-auto { margin-right: auto !important; }
  .mb-xs-auto, .my-xs-auto { margin-bottom: auto !important; }
  .ml-xs-auto, .mx-xs-auto { margin-left: auto !important; } }

@media (min-width: 576px) { .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 3rem !important; }
  .m-sm-n1 { margin: -0.25rem !important; }
  .mt-sm-n1, .my-sm-n1 { margin-top: -0.25rem !important; }
  .mr-sm-n1, .mx-sm-n1 { margin-right: -0.25rem !important; }
  .mb-sm-n1, .my-sm-n1 { margin-bottom: -0.25rem !important; }
  .ml-sm-n1, .mx-sm-n1 { margin-left: -0.25rem !important; }
  .m-sm-n2 { margin: -0.5rem !important; }
  .mt-sm-n2, .my-sm-n2 { margin-top: -0.5rem !important; }
  .mr-sm-n2, .mx-sm-n2 { margin-right: -0.5rem !important; }
  .mb-sm-n2, .my-sm-n2 { margin-bottom: -0.5rem !important; }
  .ml-sm-n2, .mx-sm-n2 { margin-left: -0.5rem !important; }
  .m-sm-n3 { margin: -1rem !important; }
  .mt-sm-n3, .my-sm-n3 { margin-top: -1rem !important; }
  .mr-sm-n3, .mx-sm-n3 { margin-right: -1rem !important; }
  .mb-sm-n3, .my-sm-n3 { margin-bottom: -1rem !important; }
  .ml-sm-n3, .mx-sm-n3 { margin-left: -1rem !important; }
  .m-sm-n4 { margin: -1.5rem !important; }
  .mt-sm-n4, .my-sm-n4 { margin-top: -1.5rem !important; }
  .mr-sm-n4, .mx-sm-n4 { margin-right: -1.5rem !important; }
  .mb-sm-n4, .my-sm-n4 { margin-bottom: -1.5rem !important; }
  .ml-sm-n4, .mx-sm-n4 { margin-left: -1.5rem !important; }
  .m-sm-n5 { margin: -3rem !important; }
  .mt-sm-n5, .my-sm-n5 { margin-top: -3rem !important; }
  .mr-sm-n5, .mx-sm-n5 { margin-right: -3rem !important; }
  .mb-sm-n5, .my-sm-n5 { margin-bottom: -3rem !important; }
  .ml-sm-n5, .mx-sm-n5 { margin-left: -3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; } }

@media (min-width: 768px) { .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 3rem !important; }
  .m-md-n1 { margin: -0.25rem !important; }
  .mt-md-n1, .my-md-n1 { margin-top: -0.25rem !important; }
  .mr-md-n1, .mx-md-n1 { margin-right: -0.25rem !important; }
  .mb-md-n1, .my-md-n1 { margin-bottom: -0.25rem !important; }
  .ml-md-n1, .mx-md-n1 { margin-left: -0.25rem !important; }
  .m-md-n2 { margin: -0.5rem !important; }
  .mt-md-n2, .my-md-n2 { margin-top: -0.5rem !important; }
  .mr-md-n2, .mx-md-n2 { margin-right: -0.5rem !important; }
  .mb-md-n2, .my-md-n2 { margin-bottom: -0.5rem !important; }
  .ml-md-n2, .mx-md-n2 { margin-left: -0.5rem !important; }
  .m-md-n3 { margin: -1rem !important; }
  .mt-md-n3, .my-md-n3 { margin-top: -1rem !important; }
  .mr-md-n3, .mx-md-n3 { margin-right: -1rem !important; }
  .mb-md-n3, .my-md-n3 { margin-bottom: -1rem !important; }
  .ml-md-n3, .mx-md-n3 { margin-left: -1rem !important; }
  .m-md-n4 { margin: -1.5rem !important; }
  .mt-md-n4, .my-md-n4 { margin-top: -1.5rem !important; }
  .mr-md-n4, .mx-md-n4 { margin-right: -1.5rem !important; }
  .mb-md-n4, .my-md-n4 { margin-bottom: -1.5rem !important; }
  .ml-md-n4, .mx-md-n4 { margin-left: -1.5rem !important; }
  .m-md-n5 { margin: -3rem !important; }
  .mt-md-n5, .my-md-n5 { margin-top: -3rem !important; }
  .mr-md-n5, .mx-md-n5 { margin-right: -3rem !important; }
  .mb-md-n5, .my-md-n5 { margin-bottom: -3rem !important; }
  .ml-md-n5, .mx-md-n5 { margin-left: -3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; } }

@media (min-width: 992px) { .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 3rem !important; }
  .m-lg-n1 { margin: -0.25rem !important; }
  .mt-lg-n1, .my-lg-n1 { margin-top: -0.25rem !important; }
  .mr-lg-n1, .mx-lg-n1 { margin-right: -0.25rem !important; }
  .mb-lg-n1, .my-lg-n1 { margin-bottom: -0.25rem !important; }
  .ml-lg-n1, .mx-lg-n1 { margin-left: -0.25rem !important; }
  .m-lg-n2 { margin: -0.5rem !important; }
  .mt-lg-n2, .my-lg-n2 { margin-top: -0.5rem !important; }
  .mr-lg-n2, .mx-lg-n2 { margin-right: -0.5rem !important; }
  .mb-lg-n2, .my-lg-n2 { margin-bottom: -0.5rem !important; }
  .ml-lg-n2, .mx-lg-n2 { margin-left: -0.5rem !important; }
  .m-lg-n3 { margin: -1rem !important; }
  .mt-lg-n3, .my-lg-n3 { margin-top: -1rem !important; }
  .mr-lg-n3, .mx-lg-n3 { margin-right: -1rem !important; }
  .mb-lg-n3, .my-lg-n3 { margin-bottom: -1rem !important; }
  .ml-lg-n3, .mx-lg-n3 { margin-left: -1rem !important; }
  .m-lg-n4 { margin: -1.5rem !important; }
  .mt-lg-n4, .my-lg-n4 { margin-top: -1.5rem !important; }
  .mr-lg-n4, .mx-lg-n4 { margin-right: -1.5rem !important; }
  .mb-lg-n4, .my-lg-n4 { margin-bottom: -1.5rem !important; }
  .ml-lg-n4, .mx-lg-n4 { margin-left: -1.5rem !important; }
  .m-lg-n5 { margin: -3rem !important; }
  .mt-lg-n5, .my-lg-n5 { margin-top: -3rem !important; }
  .mr-lg-n5, .mx-lg-n5 { margin-right: -3rem !important; }
  .mb-lg-n5, .my-lg-n5 { margin-bottom: -3rem !important; }
  .ml-lg-n5, .mx-lg-n5 { margin-left: -3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; } }

@media (min-width: 1200px) { .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 3rem !important; }
  .m-xl-n1 { margin: -0.25rem !important; }
  .mt-xl-n1, .my-xl-n1 { margin-top: -0.25rem !important; }
  .mr-xl-n1, .mx-xl-n1 { margin-right: -0.25rem !important; }
  .mb-xl-n1, .my-xl-n1 { margin-bottom: -0.25rem !important; }
  .ml-xl-n1, .mx-xl-n1 { margin-left: -0.25rem !important; }
  .m-xl-n2 { margin: -0.5rem !important; }
  .mt-xl-n2, .my-xl-n2 { margin-top: -0.5rem !important; }
  .mr-xl-n2, .mx-xl-n2 { margin-right: -0.5rem !important; }
  .mb-xl-n2, .my-xl-n2 { margin-bottom: -0.5rem !important; }
  .ml-xl-n2, .mx-xl-n2 { margin-left: -0.5rem !important; }
  .m-xl-n3 { margin: -1rem !important; }
  .mt-xl-n3, .my-xl-n3 { margin-top: -1rem !important; }
  .mr-xl-n3, .mx-xl-n3 { margin-right: -1rem !important; }
  .mb-xl-n3, .my-xl-n3 { margin-bottom: -1rem !important; }
  .ml-xl-n3, .mx-xl-n3 { margin-left: -1rem !important; }
  .m-xl-n4 { margin: -1.5rem !important; }
  .mt-xl-n4, .my-xl-n4 { margin-top: -1.5rem !important; }
  .mr-xl-n4, .mx-xl-n4 { margin-right: -1.5rem !important; }
  .mb-xl-n4, .my-xl-n4 { margin-bottom: -1.5rem !important; }
  .ml-xl-n4, .mx-xl-n4 { margin-left: -1.5rem !important; }
  .m-xl-n5 { margin: -3rem !important; }
  .mt-xl-n5, .my-xl-n5 { margin-top: -3rem !important; }
  .mr-xl-n5, .mx-xl-n5 { margin-right: -3rem !important; }
  .mb-xl-n5, .my-xl-n5 { margin-bottom: -3rem !important; }
  .ml-xl-n5, .mx-xl-n5 { margin-left: -3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; } }

@media (min-width: 1300px) { .m-xxl-0 { margin: 0 !important; }
  .mt-xxl-0, .my-xxl-0 { margin-top: 0 !important; }
  .mr-xxl-0, .mx-xxl-0 { margin-right: 0 !important; }
  .mb-xxl-0, .my-xxl-0 { margin-bottom: 0 !important; }
  .ml-xxl-0, .mx-xxl-0 { margin-left: 0 !important; }
  .m-xxl-1 { margin: 0.25rem !important; }
  .mt-xxl-1, .my-xxl-1 { margin-top: 0.25rem !important; }
  .mr-xxl-1, .mx-xxl-1 { margin-right: 0.25rem !important; }
  .mb-xxl-1, .my-xxl-1 { margin-bottom: 0.25rem !important; }
  .ml-xxl-1, .mx-xxl-1 { margin-left: 0.25rem !important; }
  .m-xxl-2 { margin: 0.5rem !important; }
  .mt-xxl-2, .my-xxl-2 { margin-top: 0.5rem !important; }
  .mr-xxl-2, .mx-xxl-2 { margin-right: 0.5rem !important; }
  .mb-xxl-2, .my-xxl-2 { margin-bottom: 0.5rem !important; }
  .ml-xxl-2, .mx-xxl-2 { margin-left: 0.5rem !important; }
  .m-xxl-3 { margin: 1rem !important; }
  .mt-xxl-3, .my-xxl-3 { margin-top: 1rem !important; }
  .mr-xxl-3, .mx-xxl-3 { margin-right: 1rem !important; }
  .mb-xxl-3, .my-xxl-3 { margin-bottom: 1rem !important; }
  .ml-xxl-3, .mx-xxl-3 { margin-left: 1rem !important; }
  .m-xxl-4 { margin: 1.5rem !important; }
  .mt-xxl-4, .my-xxl-4 { margin-top: 1.5rem !important; }
  .mr-xxl-4, .mx-xxl-4 { margin-right: 1.5rem !important; }
  .mb-xxl-4, .my-xxl-4 { margin-bottom: 1.5rem !important; }
  .ml-xxl-4, .mx-xxl-4 { margin-left: 1.5rem !important; }
  .m-xxl-5 { margin: 3rem !important; }
  .mt-xxl-5, .my-xxl-5 { margin-top: 3rem !important; }
  .mr-xxl-5, .mx-xxl-5 { margin-right: 3rem !important; }
  .mb-xxl-5, .my-xxl-5 { margin-bottom: 3rem !important; }
  .ml-xxl-5, .mx-xxl-5 { margin-left: 3rem !important; }
  .p-xxl-0 { padding: 0 !important; }
  .pt-xxl-0, .py-xxl-0 { padding-top: 0 !important; }
  .pr-xxl-0, .px-xxl-0 { padding-right: 0 !important; }
  .pb-xxl-0, .py-xxl-0 { padding-bottom: 0 !important; }
  .pl-xxl-0, .px-xxl-0 { padding-left: 0 !important; }
  .p-xxl-1 { padding: 0.25rem !important; }
  .pt-xxl-1, .py-xxl-1 { padding-top: 0.25rem !important; }
  .pr-xxl-1, .px-xxl-1 { padding-right: 0.25rem !important; }
  .pb-xxl-1, .py-xxl-1 { padding-bottom: 0.25rem !important; }
  .pl-xxl-1, .px-xxl-1 { padding-left: 0.25rem !important; }
  .p-xxl-2 { padding: 0.5rem !important; }
  .pt-xxl-2, .py-xxl-2 { padding-top: 0.5rem !important; }
  .pr-xxl-2, .px-xxl-2 { padding-right: 0.5rem !important; }
  .pb-xxl-2, .py-xxl-2 { padding-bottom: 0.5rem !important; }
  .pl-xxl-2, .px-xxl-2 { padding-left: 0.5rem !important; }
  .p-xxl-3 { padding: 1rem !important; }
  .pt-xxl-3, .py-xxl-3 { padding-top: 1rem !important; }
  .pr-xxl-3, .px-xxl-3 { padding-right: 1rem !important; }
  .pb-xxl-3, .py-xxl-3 { padding-bottom: 1rem !important; }
  .pl-xxl-3, .px-xxl-3 { padding-left: 1rem !important; }
  .p-xxl-4 { padding: 1.5rem !important; }
  .pt-xxl-4, .py-xxl-4 { padding-top: 1.5rem !important; }
  .pr-xxl-4, .px-xxl-4 { padding-right: 1.5rem !important; }
  .pb-xxl-4, .py-xxl-4 { padding-bottom: 1.5rem !important; }
  .pl-xxl-4, .px-xxl-4 { padding-left: 1.5rem !important; }
  .p-xxl-5 { padding: 3rem !important; }
  .pt-xxl-5, .py-xxl-5 { padding-top: 3rem !important; }
  .pr-xxl-5, .px-xxl-5 { padding-right: 3rem !important; }
  .pb-xxl-5, .py-xxl-5 { padding-bottom: 3rem !important; }
  .pl-xxl-5, .px-xxl-5 { padding-left: 3rem !important; }
  .m-xxl-n1 { margin: -0.25rem !important; }
  .mt-xxl-n1, .my-xxl-n1 { margin-top: -0.25rem !important; }
  .mr-xxl-n1, .mx-xxl-n1 { margin-right: -0.25rem !important; }
  .mb-xxl-n1, .my-xxl-n1 { margin-bottom: -0.25rem !important; }
  .ml-xxl-n1, .mx-xxl-n1 { margin-left: -0.25rem !important; }
  .m-xxl-n2 { margin: -0.5rem !important; }
  .mt-xxl-n2, .my-xxl-n2 { margin-top: -0.5rem !important; }
  .mr-xxl-n2, .mx-xxl-n2 { margin-right: -0.5rem !important; }
  .mb-xxl-n2, .my-xxl-n2 { margin-bottom: -0.5rem !important; }
  .ml-xxl-n2, .mx-xxl-n2 { margin-left: -0.5rem !important; }
  .m-xxl-n3 { margin: -1rem !important; }
  .mt-xxl-n3, .my-xxl-n3 { margin-top: -1rem !important; }
  .mr-xxl-n3, .mx-xxl-n3 { margin-right: -1rem !important; }
  .mb-xxl-n3, .my-xxl-n3 { margin-bottom: -1rem !important; }
  .ml-xxl-n3, .mx-xxl-n3 { margin-left: -1rem !important; }
  .m-xxl-n4 { margin: -1.5rem !important; }
  .mt-xxl-n4, .my-xxl-n4 { margin-top: -1.5rem !important; }
  .mr-xxl-n4, .mx-xxl-n4 { margin-right: -1.5rem !important; }
  .mb-xxl-n4, .my-xxl-n4 { margin-bottom: -1.5rem !important; }
  .ml-xxl-n4, .mx-xxl-n4 { margin-left: -1.5rem !important; }
  .m-xxl-n5 { margin: -3rem !important; }
  .mt-xxl-n5, .my-xxl-n5 { margin-top: -3rem !important; }
  .mr-xxl-n5, .mx-xxl-n5 { margin-right: -3rem !important; }
  .mb-xxl-n5, .my-xxl-n5 { margin-bottom: -3rem !important; }
  .ml-xxl-n5, .mx-xxl-n5 { margin-left: -3rem !important; }
  .m-xxl-auto { margin: auto !important; }
  .mt-xxl-auto, .my-xxl-auto { margin-top: auto !important; }
  .mr-xxl-auto, .mx-xxl-auto { margin-right: auto !important; }
  .mb-xxl-auto, .my-xxl-auto { margin-bottom: auto !important; }
  .ml-xxl-auto, .mx-xxl-auto { margin-left: auto !important; } }

.text-monospace { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify { text-align: justify !important; }

.text-wrap { white-space: normal !important; }

.text-nowrap { white-space: nowrap !important; }

.text-truncate { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.text-left { text-align: left !important; }

.text-right { text-align: right !important; }

.text-center { text-align: center !important; }

@media (min-width: 410px) { .text-xs-left { text-align: left !important; }
  .text-xs-right { text-align: right !important; }
  .text-xs-center { text-align: center !important; } }

@media (min-width: 576px) { .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; } }

@media (min-width: 768px) { .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; } }

@media (min-width: 992px) { .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; } }

@media (min-width: 1200px) { .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; } }

@media (min-width: 1300px) { .text-xxl-left { text-align: left !important; }
  .text-xxl-right { text-align: right !important; }
  .text-xxl-center { text-align: center !important; } }

.text-lowercase { text-transform: lowercase !important; }

.text-uppercase { text-transform: uppercase !important; }

.text-capitalize { text-transform: capitalize !important; }

.font-weight-light { font-weight: 300 !important; }

.font-weight-lighter { font-weight: lighter !important; }

.font-weight-normal { font-weight: 400 !important; }

.font-weight-bold { font-weight: 700 !important; }

.font-weight-bolder { font-weight: bolder !important; }

.font-italic { font-style: italic !important; }

.text-white { color: #fff !important; }

.text-body { color: #212529 !important; }

.text-muted { color: #6c757d !important; }

.text-black-50 { color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 { color: rgba(255, 255, 255, 0.5) !important; }

.text-decoration-none { text-decoration: none !important; }

.text-reset { color: inherit !important; }

@font-face { font-display: swap; font-family: "Geometria"; font-weight: normal; font-style: normal; src: url("../fonts/Geometria.eot"); src: url("../fonts/Geometria.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria.woff") format("woff"), url("../fonts/Geometria.ttf") format("truetype"); }

@font-face { font-display: swap; font-family: "Geometria"; font-weight: 700; font-style: normal; src: url("../fonts/Geometria.eot"); src: url("../fonts/Geometria.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria.woff") format("woff"), url("../fonts/Geometria.ttf") format("truetype"); }

@font-face { font-display: swap; font-family: "HelveticaNeueCyr"; font-weight: normal; font-style: normal; src: url("../fonts/HelveticaNeueCyr-Bold.eot"); src: url("../fonts/HelveticaNeueCyr-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueCyr-Bold.woff") format("woff"), url("../fonts/HelveticaNeueCyr-Bold.ttf") format("truetype"); }

::placeholder { color: #646464; }

html, body { height: 100%; }

.main { flex: 1 0 auto; }

html { font-size: 10px; }

body { font-size: 1.5rem; min-width: 320px; position: relative; line-height: 1.8; font-family: "Roboto", sans-serif; overflow-x: hidden; color: #000; display: flex; flex-direction: column; }

body input, body textarea { display: block; width: 100%; outline: none; resize: none; border: none; }

body input:active, body input:hover, body input:focus, body textarea:active, body textarea:hover, body textarea:focus, body button:active, body button:hover, body button:focus, body a:active, body a:hover, body a:focus { outline: 0; outline-offset: 0; }

body button { cursor: pointer; background-color: transparent; border: none; padding: 0; }

b, strong { font-weight: 700; }

p { margin-bottom: 0; }

a { color: #2a6883; text-decoration: none; transition: 0.25s; }

a:hover { color: #2a6883; text-decoration: underline; }

a[href^="#"]:hover, a[href^="tel"]:hover, a[href^="mail"]:hover { text-decoration: none; }

a.link-decorated { text-decoration: underline; font-size: 1.7rem; }

a.link-decorated:hover { text-decoration: none; }

a.link-more { font-family: "HelveticaNeueCyr", sans-serif; font-size: 1.3rem; color: #000; }

.w-100 { width: 100%; }

.h-100 { height: 100%; }

.list-unstyled { padding-left: 0; list-style: none; margin-bottom: 0; }

.icon { display: inline-block; vertical-align: middle; top: -0.3rem; background-repeat: no-repeat; background-position: center; background-size: contain; }

.icon-left { margin-right: 1rem; }

.icon-right { margin-left: 1rem; }

.icon-location { width: 1.3rem; height: 1.7rem; }

.icon-location_w { width: 1.5rem; height: 2.1rem; }

.icon-close { width: 2rem; height: 2rem; }

.icon-hamb { width: 2.6rem; height: 2rem; }

.icon-arrow { width: 0.5rem; height: 0.9rem; }

.icon-search { width: 1.6rem; height: 1.6rem; }

.icon-profes { width: 1.6rem; height: 1.6rem; }

.icon-reviews { width: 1.6rem; height: 1.4rem; }

.icon-calen, .icon-world, .icon-phone { width: 1.6rem; height: 1.6rem; }

.icon-mail { width: 1.8rem; height: 1.4rem; }

.icon-list { width: 2.1rem; height: 1.7rem; }

.image { display: block; background-position: center; background-repeat: no-repeat; position: relative; }

.image-con { background-size: contain; }

.image-cov { background-size: cover; }

.image-hover { overflow: hidden; cursor: pointer; }

.image-hover:before { content: ''; width: 100%; height: 100%; background: inherit; position: absolute; top: 0; left: 0; transition: 0.4s; }

.image-hover:hover:before { transform: scale(1.05, 1.05); }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { display: block; margin-bottom: 1.2rem; line-height: 1.2; font-family: "HelveticaNeueCyr", sans-serif; }

h1, .h1 { font-size: 3rem; }

h2, .h2 { font-size: 2.4rem; }

h3, .h3 { font-size: 2.2rem; }

h4, .h4 { font-size: 1.8rem; }

h5, .h5 { font-size: 1.6rem; }

h6, .h6 { font-size: 1.5rem; }

.btn { display: flex; cursor: pointer; transition: 0.25s; justify-content: center; align-items: center; text-decoration: none !important; line-height: 1.2; text-align: center; }

.btn:after { content: ""; display: inline-block; min-height: inherit; visibility: hidden; }

.btn-s { height: 3.5rem; min-height: 3.5rem; padding-left: 1.7rem; padding-right: 1.7rem; font-size: 1.3rem; }

.btn-m { font-family: "HelveticaNeueCyr", sans-serif; height: 5.1rem; min-height: 5.1rem; padding-left: 2.14rem; padding-right: 2.14rem; }

.btn-blue { background-color: #71afca; color: #ffffff; }

.btn-blue:hover { background-color: #4c9abc; color: #ffffff; }

.btn-white { background-color: #ffffff; border: 0.1rem solid #71afca; color: #000; }

.btn-white:hover { background-color: #71afca; color: #fff; }

.hidden, .form-checkbox, .form-radio { position: absolute; top: 0; left: 0; opacity: 0; visibility: hidden; pointer-events: none; }

.row-xs { display: flex; flex-wrap: wrap; margin-right: -0.3rem; margin-left: -0.3rem; }

.row-xs > * { padding-left: .3rem; padding-right: .3rem; }

.form { display: block; }

.form-group { position: relative; }

.form-group:not(:first-child) { margin-top: 1.2rem; }

.form-submit { margin-top: 1.2rem; }

.form-submit .btn { margin: 0 auto; }

.form-control { border: 0.1rem solid #71afca; height: 3.5rem; line-height: 3.3rem; padding: 0 1.5rem; color: #000000; font-size: 1.3rem; }

.form textarea.form-control { height: 17rem; padding-top: .7rem; padding-bottom: .7rem; }

.form-checkbox + .form-label { cursor: pointer; padding-left: 3rem; position: relative; margin-bottom: 0; display: block; }

.form-checkbox + .form-label:before, .form-checkbox + .form-label:after { position: absolute; top: 50%; transform: translateY(-50%); left: 0; }

.form-checkbox + .form-label:before { content: ''; width: 1.4rem; height: 1.4rem; border: 0.1rem solid #000000; }

.form-checkbox + .form-label:after { content: '\2714'; width: 1.4rem; height: 1.4rem; line-height: 1.4rem; text-align: center; opacity: 0; transition: .25s; }

.form-checkbox:checked + .form-label:after { opacity: 1; }

.form-radio + .form-label { cursor: pointer; padding-left: 3rem; position: relative; margin-bottom: 0; display: block; }

.form-radio + .form-label:before, .form-radio + .form-label:after { content: ''; position: absolute; top: 50%; transform: translateY(-50%); border-radius: 100%; }

.form-radio + .form-label:before { width: 1.4rem; height: 1.4rem; border: 0.1rem solid #000000; left: 0; }

.form-radio + .form-label:after { width: 0.6rem; height: 0.6rem; opacity: 0; transition: .25s; background-color: #000000; left: .4rem; }

.form-radio:checked + .form-label:after { opacity: 1; }

.form-profession { position: relative; margin-bottom: calc(3rem + .2vh); }

.form-profession .icon-search { position: absolute; left: 1.4rem; top: 50%; transform: translateY(-50%); }

.form-profession .form-control { padding-left: 3.5rem; }

.bg-white { background-color: #f9f9f9; color: #000; }

.baner { background-color: #e9e9e9; position: relative; height: 100%; margin: 0 auto; }

.baner:before { content: attr(data-title); color: #999999; font-family: "Geometria", sans-serif; font-size: 1.3rem; display: block; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

.baner img { position: relative; z-index: 2; }

.baner-top { flex: 0 0 6rem; width: 100%; }

.baner-content { margin-bottom: 2.3rem; }

.baner-content .row > * { margin-bottom: 1.5rem; }

.section { margin-bottom: calc(3rem + 2.8vh); }

.section-title { margin-bottom: calc(1.5rem + 2.3vh); }

.label { font-size: 1.3rem; display: block; color: #ffffff; height: 2rem; line-height: 2rem; padding-left: .8rem; padding-right: .8rem; }

.label-green { background-color: #2f7b23; }

.label-yellow { background-color: #dea105; }

.label-gray { background-color: #7b7b7b; }

.is-menu, .is-menu body { overflow: hidden; position: relative; height: 100%; }

.is-menu .header-nav { opacity: 1; visibility: visible; pointer-events: auto; }

.header { background-color: #f9f9f9; padding-top: 3.1rem; padding-bottom: 2.7rem; line-height: 1.2; position: relative; }

.header-nav_link { color: #000; }

.header-nav_link:hover { color: #000; }

.header-city_link { color: #71afca; border-bottom: 0.2rem dashed #71afca; text-decoration: none !important; font-family: "HelveticaNeueCyr", sans-serif; }

.header-city_link:hover { color: #71afca; border-color: transparent; }

.header-mob_open, .header-mob_close, .header-mob_location { display: block; cursor: pointer; transition: 0.25s; }

.header-mob_close { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 0; pointer-events: none; opacity: 0; visibility: hidden; }

.header-mob_location { display: flex; justify-content: center; align-items: center; width: 7rem; height: 7rem; background-color: #71afca; }

.header-dropdown { position: absolute; opacity: 0; pointer-events: none; visibility: hidden; transition: 0.4s; top: 100%; left: 0; right: 0; background-color: #ffffff; z-index: 3; padding-top: 5rem; padding-bottom: 5rem; border-bottom: .1rem solid #f9f9f9; overflow-y: auto; }

.header-dropdown_list { left: 1.2rem; }

.header-dropdown_list:not(:last-child) { margin-bottom: 5rem; }

.header-dropdown_list.column-2 { columns: 2; }

.header-dropdown_list li:not(:first-child) { margin-top: 1.3rem; }

.header-dropdown .h2 { color: #d6d6d6; margin-bottom: 2rem; }

.header.is-dropdown .header-dropdown { opacity: 1; pointer-events: auto; visibility: visible; }

.is-menu .header-mob_open { pointer-events: none; opacity: 0; visibility: hidden; }

.is-menu .header-mob_close { opacity: 1; pointer-events: auto; visibility: visible; }

.breadcrumbs { padding-top: calc(1rem + 1vh); padding-bottom: calc(1rem + 1.3vh); }

.breadcrumbs-list_item:not(:first-child) { margin-left: 3rem; position: relative; }

.breadcrumbs-list_item:not(:first-child):before { content: '—'; font-size: 1.3rem; color: #646464; left: -2rem; position: absolute; top: 50%; transform: translateY(-50%); }

.breadcrumbs-list_link { color: #646464; font-size: 1.3rem; }

.promo { color: #ffffff; margin-bottom: calc(3rem + 0.3vh); }

.promo-box { padding-top: calc(3rem + 33.3vh); }

.promo-block { height: 100%; padding-top: 3.8rem; padding-bottom: 2.9rem; padding-right: 6rem; }

.promo-block .h1 { margin-bottom: 1.4rem; }

.promo-block p { line-height: 1.2; margin-bottom: 2.7rem; }

.promo-block .link-more { margin-left: 2.6rem; }

.promo-block.bg-white { padding-top: 3rem; padding-right: 2.9rem; padding-left: 2.9rem; }

.promo-block.bg-white .h1 { margin-bottom: 2.1rem; }

.promo-list { margin-bottom: 1.2rem; }

.promo-list_item:not(:first-child) { margin-top: 2.5rem; }

.promo-list_link { display: flex; width: wrap; text-decoration: underline; line-height: 1.2; }

.promo-list_link:hover { text-decoration: none; }

.promo-list_link .image { width: 3.5rem; height: 3.5rem; flex: 0 0 3.5rem; margin-right: 0.9rem; }

.directions-block { border: 0.1rem solid #71afca; padding-bottom: 101.9%; position: relative; }

.directions-block_inner { position: absolute; width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: space-between; align-items: center; color: #000; text-decoration: none !important; padding-top: .5rem; padding-bottom: 2.5rem; }

.directions-block_inner:before { content: ''; display: block; height: 1.8rem; }

.directions-block_inner:hover { background-color: #f9f9f9; }

.directions-block_inner:hover .image { transform: translateY(-0.5rem); }

.directions-block .image { width: 5rem; height: 5rem; transition: 0.25s; }

.directions-block .h6 { margin-bottom: 0; }

.directions .baner { width: 24rem; height: 40rem; }

.news .news-box { margin-bottom: -3.7rem; }

.news-box > * { margin-bottom: 3.7rem; }

.news-box > *:not(.info-block):nth-child(even) { margin-left: auto; margin-right: auto; }

.news-detail { margin-top: -1rem; margin-bottom: calc(1.5rem + 1.7vh); }

.news-image { padding-bottom: 38%; margin-bottom: calc(1.5rem + 2vh); }

.article { margin-bottom: calc(3rem + 3.2vh); }

.article p:not(:last-child) { margin-bottom: calc(1.5rem + 1.9vh); }

.article-info { margin-top: 3rem; margin-bottom: calc(3rem + 1vh); padding: 2rem 3rem; }

.article-info .image { width: 6rem; height: 6rem; }

.article-info_text { display: block; color: #646464; font-size: 1.3rem; }

.article-info_link { text-decoration: underline; }

.article-info_link:hover { text-decoration: none; }

.article ul { list-style: none; padding-left: 0; margin-bottom: calc(1.5rem + 1.4vh); }

.article ul li { padding-left: 3.4rem; position: relative; }

.article ul li:not(:first-child) { margin-top: .6rem; }

.article ul li:before { content: ''; display: block; width: 0.5rem; height: 0.5rem; background-color: #71afca; position: absolute; left: 0; border-radius: 100%; top: 1.1rem; }

.reviews-box { border-bottom: .1rem solid #F0F0F0; }

.reviews-box .info-block { padding-top: calc(1.5rem + 1.9vh); padding-bottom: calc(1.5rem + 1.3vh); position: relative; }

.reviews-box .info-block:before { content: ''; width: calc(100% - 3rem); height: 0.1rem; background-color: #F0F0F0; position: absolute; top: 0; left: 50%; transform: translateX(-50%); }

.reviews-functional { margin-bottom: calc(2rem + 1vh); }

.reviews-functional > * { margin-bottom: 1rem; }

.reviews-rating { position: relative; }

.reviews-rating_pointer { content: ''; position: absolute; display: block; width: 2.1rem; height: 3.4rem; pointer-events: none; top: 0; background: url(../img/icon/icon-rating.svg) center no-repeat; transform: translateX(-1.02rem); }

.reviews-rating_box { display: flex; flex-wrap: nowrap; position: relative; border-bottom: .3rem solid #E1E1E1; margin-bottom: 1.2rem; }

.reviews-rating_item { cursor: pointer; position: relative; height: 3.4rem; position: relative; width: calc(100%/7); }

.reviews-rating_item:hover:after { background-color: #c8c8c8; }

.reviews-rating_item:after { content: ''; display: block; width: 0.3rem; height: 1rem; background-color: #e1e1e1; position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); }

.reviews-rating_item:first-child, .reviews-rating_item:last-child { width: calc(100%/16); }

.reviews-rating_item:first-child:after { left: 0; transform: translateY(0); }

.reviews-rating_item:last-child:after { left: auto; right: 0; transform: translateY(0); }

.reviews-rating_text { display: block; font-size: 1.3rem; color: #646464; }

.reviews-date_text { display: block; color: #646464; font-size: 1.3rem; }

.reviews-date .form-control { width: 7rem; text-align: center; padding-left: 0; padding-right: 0; }

.alphabet-box { margin-bottom: .8rem; margin-right: -0.90rem; margin-left: -0.90rem; }

.alphabet-box > * { padding-left: .90rem; padding-right: .90rem; }

.alphabet-link { display: block; min-width: 1.6rem; border-bottom: .2rem solid transparent; text-transform: uppercase; text-align: center; color: #000; font-size: 1.6rem; }

.alphabet-link:hover, .alphabet-link.is-active { border-color: #71afca; }

.alphabet-link.is-active { font-weight: 700; }

.professions-box { margin-top: 2.6rem; margin-left: -.75rem; margin-right: -.75rem; }

.professions-box > * { margin-bottom: 1.5rem; padding-left: 0.75rem; padding-right: 0.75rem; }

.professions-block { display: flex; background-color: #f9f9f9; height: 7.5rem; min-height: 7.5rem; align-items: center; justify-content: center; font-family: "HelveticaNeueCyr", sans-serif; text-decoration: none !important; color: #000; }

.professions-block:hover { background-color: #e0e0e0; color: #000; }

.info-block { text-decoration: none !important; color: #000000; line-height: 1.2; }

.info-block .image { width: 10.1rem; height: 10.1rem; }

.info-block_date, .info-block_category { display: block; font-size: 1.3rem; }

.info-block_date { color: #646464; margin-bottom: 1.1rem; }

.info-block_category { color: #2a6883; position: relative; }

.info-block_category:before { content: ''; width: 0.6rem; height: 0.6rem; border-radius: 100%; position: absolute; top: 50%; transform: translateY(-50%); background-color: #71afca; left: -1.8rem; }

.info-block_content .row { margin-bottom: .9rem; }

.info-block_content p { line-height: 1.5; font-size: 1.3rem; color: #646464; }

.info-count { margin-top: 2.5rem; }

.info-count > *:not(:first-child) { margin-left: 2.4vw; }

.info-count_text { display: block; font-size: 1.3rem; color: #646464; }

.info-functional { font-size: 1.3rem; margin-top: 2rem; }

.info-functional strong { font-size: 1.5rem; }

.info-functional a { text-decoration: underline; }

.info-functional a:hover { text-decoration: none; }

.info-functional .btn { min-width: 16.3rem; }

.info-block .row > .col-auto > .info-block_date, .doors-block .row > .col-auto > .info-block_date { margin-bottom: 0; }

.location { padding-top: 2.4vh; }

.location .baner { width: 30rem; height: 10rem; }

.location .baner:not(:first-child) { margin-top: 4rem; }

.location-list { margin-bottom: 1.8rem; }

.location-list_item:not(:first-child) { margin-top: .53rem; }

.location .link-more { margin-left: .3rem; }

.doors-box { border-bottom: 1px solid #F0F0F0; }

.doors-box .h1 { color: #e9e9e9; margin-bottom: -1.2rem; }

.doors-block { display: block; border-top: 1px solid #F0F0F0; padding-top: calc(1.5rem + 1.2vh); padding-bottom: calc(1.5rem + 2vh); color: #000; text-decoration: none !important; }

.doors-item { display: block; color: #000; text-decoration: none !important; line-height: 1.2; margin-top: calc(3rem + 1.2vh); }

.doors-item_image { display: flex; align-items: center; justify-content: center; width: 10rem; height: 10rem; background-color: #f9f9f9; }

.doors-item_image .image { width: 5.3rem; height: 5.3rem; }

.doors-item_content > .row { margin-bottom: .9rem; }

.doors-item_content p { font-size: 1.3rem; color: #646464; line-height: 1.5; }

.box { border-bottom: .1rem solid #F0F0F0; }

.colleges-box { border-bottom: 1px solid #F0F0F0; }

.colleges-box .doors-item_content > .row { margin-bottom: 0.6rem; }

.colleges-box .doors-item_content .h4 { margin-bottom: 0.8rem; color: #000; }

.colleges-box .doors-block a:hover { text-decoration: none; }

.colleges-box .doors-block:hover .h4 { color: #2a6883; }

.colleges-description { margin-bottom: 2.5rem; }

.colleges-map { display: none; }

.colleges-top { padding: calc(1.5rem + 4vh) calc(3rem + 3.4vh) calc(1.5rem + 3.4vh); position: relative; display: block; text-decoration: none !important; }

.colleges-top .image { width: 5.3rem; height: 5.3rem; }

.colleges-top:before { content: 'Реклама'; height: 100%; text-align: center; position: absolute; left: 0; top: 0; background-color: #71afca; color: #ffffff; width: 2.5rem; writing-mode: vertical-rl; display: block; transform: rotate(-180deg); }

.colleges-top .h4 { margin-bottom: .6rem; }

.colleges-top p { font-size: 1.3rem; color: #646464; }

.colleges-search { margin-left: -.75rem; margin-right: -.75rem; margin-bottom: 1.5rem; }

.colleges-search > * { padding-left: 0.75rem; padding-right: 0.75rem; }

.colleges-search .btn { width: 30rem; justify-content: space-between; }

.colleges-search .btn:after { display: none; }

.colleges-search .btn .icon-list { display: none; }

.colleges-status { margin-left: -.35rem; margin-right: -.35rem; margin-bottom: -.5rem; }

.colleges-status > * { padding-left: 0.35rem; padding-right: 0.35rem; margin-bottom: 0.5rem; }

#map { height: 60vh; }

.college { margin-bottom: 2.4rem; }

.college .btn-s { font-family: "HelveticaNeueCyr", sans-serif; font-size: 1.5rem; }

.college-image { width: 12.8rem; height: 12.8rem; display: flex; justify-content: center; align-items: center; background-color: #f9f9f9; }

.college-image .image { width: 7.4rem; height: 7.4rem; }

.college-info { margin-bottom: 1.4rem; }

.college-info > *:not(:first-child) .college-info_text:before { content: ''; width: 0.6rem; height: 0.6rem; border-radius: 100%; position: absolute; top: 50%; transform: translateY(-50%); background-color: #71afca; left: -1.8rem; }

.college-info_text { font-size: 1.3rem; color: #646464; position: relative; }

.college-info_type { color: #2A6883; }

.college-control { border-bottom: .1rem solid #F0F0F0; margin-bottom: 3rem; }

.college-control .row { margin-left: -.6rem; margin-right: -.6rem; }

.college-control .row > * { padding-left: .6rem; padding-right: .6rem; }

.college-control_link { display: flex; justify-content: center; align-items: center; height: 4.2rem; min-height: 4.2rem; cursor: pointer; border: .1rem solid #F0F0F0; border-bottom: none; position: relative; font-family: "HelveticaNeueCyr", sans-serif; padding-left: 1.6rem; padding-right: 1.6rem; }

.college-control_link:hover, .college-control_link.is-active { color: #2A6883; }

.college-control_link.is-active:after { content: ''; position: absolute; bottom: -.1rem; left: 0; height: 0.1rem; width: 100%; background-color: #ffffff; }

.college-tab:not(:first-child) { display: none; }

.college-tab .doors-block { border-top: none; border-bottom: .1rem solid #f0f0f0; }

.college-tab .doors-block:first-child { margin-top: -3rem; }

.college-tab .reviews-box { margin-top: -3rem; }

.college-tab .reviews-box .info-block:first-child:before { display: none; }

.college-tabs { margin-bottom: 3rem; }

.college-contact { padding: calc(1.5rem + 2vh) calc(1.5rem + 2.4vh); margin-bottom: calc(3rem + .7vh); }

.college-contact .row > *:not(:first-child) { margin-left: 1.7vw; }

.college-contact_title { display: block; font-size: 1.3rem; color: #646464; margin-bottom: .5rem; }

.college-contact_text, .college-contact_link { font-family: "HelveticaNeueCyr", sans-serif; }

.college-contact_text { color: #000; }

.college-functional { margin-bottom: -1rem; }

.college-functional > * { margin-bottom: 1rem; }

.content { padding-right: 5rem; }

.aside .baner:not(:last-child) { margin-bottom: calc(3rem + 1vh); }

.aside h2 { margin-bottom: calc(1.5rem + .8vh); }

.aside-list { margin-bottom: calc(3rem + 1vh); }

.aside-list li:not(:first-child) { line-height: 1.2; margin-top: 1.3rem; }

.pagination-link { display: block; font-size: 1.6rem; color: #000; padding-bottom: .3rem; }

.pagination-box { margin-top: 5.5rem; }

.pagination-box > *:not(:first-child) { margin-left: 2.1rem; }

.pagination-box span { color: #646464; }

.pagination-box a { font-weight: 700; border-bottom: 0.2rem solid #71afca; min-width: 1.6rem; text-align: center; }

.filter-box { margin-bottom: 4.9rem; }

.filter-date { margin-top: -1.2rem; margin-bottom: calc(3rem + .5vh); }

.filter-date .form-control { width: 22.4rem; padding-right: 4rem; cursor: pointer; }

.filter-date .form-group .icon { position: absolute; top: 50%; transform: translateY(-50%); pointer-events: none; right: 1.8rem; }

.filter-block { margin-bottom: 1rem; }

.filter-block_link { height: 3.5rem; display: flex; min-height: 3.5rem; align-items: center; justify-content: center; padding-left: 1.6rem; padding-right: 1.6rem; font-family: "HelveticaNeueCyr", sans-serif; border: 0.1rem solid #71afca; background-color: #ffffff; text-decoration: none !important; color: #000; }

.filter-block_link:hover { background-color: #71afca; color: #ffffff; }

.filter-block_link.is-active { background-color: #71afca; color: #ffffff; }

.tabs-control { margin-bottom: calc(3rem + 2vh); }

.tabs-control > * { margin-bottom: 1rem; }

.tabs-control_link { height: 3.5rem; display: flex; min-height: 3.5rem; align-items: center; justify-content: center; padding-left: 1.6rem; padding-right: 1.6rem; font-family: "HelveticaNeueCyr", sans-serif; border: 0.1rem solid #71afca; background-color: #ffffff; text-decoration: none !important; color: #000; }

.tabs-control_link:hover { background-color: #71afca; color: #ffffff; }

.tabs-control_link.is-active { background-color: #71afca; color: #ffffff; }

.popup { display: none; width: 40rem; padding: 3.5rem 4rem; }

.popup .h1 { text-align: center; margin-bottom: 1.9rem; }

.popup-info { display: block; font-size: 1.3rem; color: #646464; }

.popup .form { margin-bottom: 2.6rem; }

.popup .form-submit .btn { width: 100%; height: 4rem; min-height: 4rem; font-size: 1.5rem; font-family: "HelveticaNeueCyr", sans-serif; }

.popup#reviews { width: 72rem; }

.popup#reviews .h1 { text-align: left; }

.popup#reviews .form-group:not(:first-child) { margin-top: 3rem; }

.popup#reviews .article-info { padding: 1.6rem 2rem; }

.popup#reviews .article-info .row { margin-left: -1rem; margin-right: -1rem; }

.popup#reviews .article-info .row > * { padding-left: 1rem; padding-right: 1rem; }

.footer { background-color: #f9f9f9; padding-top: 2.8rem; padding-bottom: 2.8rem; }

.footer-copy_text { display: block; font-size: 1.3rem; color: #646464; margin-left: 1.4rem; margin-right: 1.4rem; }

.footer-author { display: flex; align-items: center; }

.footer-author_text { display: block; font-family: "Geometria", sans-serif; font-size: 1.3rem; color: #646464; margin-right: 0.4rem; }

/*==========  Desktop First  ==========*/
@media (max-width: 1299.98px) { .alphabet-box { margin-left: -0.5rem; margin-right: -.5rem; }
  .alphabet-box > * { padding-left: 0.5rem; padding-right: 0.5rem; } }

@media (max-width: 1199.98px) { .header-nav_item { padding-left: 0.5rem; padding-right: 0.5rem; }
  .promo-block { padding-right: 0; }
  .content { padding-right: 1.5rem; }
  .alphabet-box { margin-left: -0.25rem; margin-right: -.25rem; }
  .alphabet-box > * { padding-left: 0.25rem; padding-right: 0.25rem; }
  .college-control { overflow-x: auto; }
  .college-control > .row { flex-wrap: nowrap; }
  .college-control_link.is-active:after { display: none; }
  .college-contact .row > :not(:first-child) { margin-left: 0; } }

@media (max-width: 991.98px) { .header-nav { opacity: 0; visibility: hidden; pointer-events: none; position: absolute; top: 100%; left: 0; right: 0; z-index: 2; background-color: #ffffff; border-top: 0.3rem solid #71afca; overflow-y: auto; height: calc(100vh - 13rem); transition: 0.4s; }
  .header-nav_item { margin-top: 3.2rem; padding-left: 1.5rem; }
  .header-dropdown { overflow-y: auto; height: calc(100vh - 13rem); transform: translateX(-100%); border-top: 0.3rem solid #71afca; padding-top: 3rem; padding-bottom: 11rem; }
  .header.is-dropdown .header-dropdown { transform: translateX(0); }
  .header-dropdown_list { margin-bottom: 3rem; }
  .header-dropdown .row > *:last-child .header-dropdown_list:last-child { margin-bottom: 0; }
  .promo-box { padding-top: calc(3rem + 5vh); }
  .header { padding-top: 2.6rem; padding-bottom: 2.2rem; } }

@media (max-width: 767.98px) { .promo-block.bg-white { padding-top: 2rem; padding-left: 1.5rem; padding-right: 1.5rem; }
  .alphabet-box { flex-wrap: nowrap; overflow-x: auto; }
  .info-count > :not(:first-child) { margin-left: 0; }
  #reviews { width: 95vw; } }

@media (max-width: 575.98px) { .header { padding-top: 0; padding-bottom: 0; }
  .header-dropdown_list.column-2 { columns: 1; }
  .promo-box > *:not(:first-child) { margin-top: 2.3rem; }
  .promo-box > *:last-child { margin-bottom: -21rem; }
  .promo { margin-bottom: 24rem; }
  .promo-block:not(.bg-white) .h1, .promo-block:not(.bg-white) p { text-align: center; }
  .info-block_category:before { display: none; }
  .article-info { text-align: center; }
  .colleges-search { margin-bottom: 3rem; }
  .colleges-search .form-profession { margin-bottom: 1rem; }
  .colleges-search .btn { width: 100%; }
  .header > .container { max-width: none; padding-right: 0; }
  .header > .container > .row { margin-left: 0; margin-right: 0; }
  .header > .container > .row > * { padding-left: 0; padding-right: 0; }
  .header-nav_list { margin-left: 0; margin-right: 0; } }

@media (max-width: 409.98px) { .breadcrumbs { display: none; }
  .baner-content { margin-top: 3rem; }
  .location .baner, .baner-content .baner { max-width: 30rem; width: 100% !important; }
  h1, .h1 { font-size: 2.6rem; }
  .directions-box { margin-left: -1.5rem; margin-right: -1.5rem; }
  .filter-date .form-control { width: 100%; }
  .popup { width: 95vw; }
  .colleges-top { padding: 1.5rem 1.5rem 1.5rem 4rem; }
  .info-functional .btn { width: 100%; }
  .popup { padding: 2rem 1.5rem; }
  .college-control { overflow: visible; border-bottom: 0; }
  .college-control > .row { flex-wrap: wrap; }
  .college-control > .row > * { margin-bottom: 1rem; }
  .college-control_link { border-bottom: .1rem solid #f0f0f0; }
  .college-functional .btn { width: 100%; }
  .college-info > :not(:first-child) .college-info_text:before { display: none; } }

/*==========  Mobile First  ==========*/
@media (min-width: 992px) { .directions-box > * { flex: 0 0 20%; max-width: 20%; }
  .aside { width: 27rem; flex: 0 0 27rem; } }
