@import "vars";


/*==========  Desktop First  ==========*/
// ≥1300px
@include media-breakpoint-down(xl) {
	.alphabet-box {
		margin: {
			left: -0.5rem;
			right: -.5rem; }
		> * {
			padding: {
				left: 0.5rem;
				right: 0.5rem; } } } }
// ≥1200px
@include media-breakpoint-down(lg) {
	.header-nav_item {
		padding: {
			left: 0.5rem;
			right: 0.5rem; } }
	.promo-block {
		padding-right: 0; }
	.content {
		padding-right: 1.5rem; }
	.alphabet-box {
		margin: {
			left: -0.25rem;
			right: -.25rem; }
		> * {
			padding: {
				left: 0.25rem;
				right: 0.25rem; } } }
	.college-control {
		overflow-x: auto;
		> .row {
			flex-wrap: nowrap; }
		&_link.is-active:after {
			display: none; } }
	.college-contact .row>:not(:first-child) {
			margin-left: 0; } }
// ≥992px
@include media-breakpoint-down(md) {
	.header-nav {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 2;
		background-color: #ffffff;
		border-top: .3rem solid $accent;
		overflow-y: auto;
		height: calc(100vh - 13rem);
		transition: 0.4s;
		&_item {
			margin-top: 3.2rem;
			padding-left: 1.5rem; } }
	.header-dropdown {
		overflow-y: auto;
		height: calc(100vh - 13rem);
		transform: translateX(-100%);
		border-top: .3rem solid $accent;
		padding: {
			top: 3rem;
			bottom: 11rem; } }
	.header.is-dropdown {
		.header-dropdown {
			transform: translateX(0); } }
	.header-dropdown {
		&_list {
			margin-bottom: 3rem; }
		.row {
			> *:last-child {
				.header-dropdown_list:last-child {
					margin-bottom: 0; } } } }
	.promo-box {
		padding-top: calc(3rem + 5vh); }
	.header {
		padding: {
			top: 2.6rem;
			bottom: 2.2rem; } } }
// ≥768px
@include media-breakpoint-down(sm) {
	.promo-block.bg-white {
		padding: {
			top: 2rem;
			left: 1.5rem;
			right: 1.5rem; } }
	.alphabet-box {
		flex-wrap: nowrap;
		overflow-x: auto; }
	.info-count>:not(:first-child) {
		margin-left: 0; }
	#reviews {
		width: 95vw; } }
// ≥576px
@include media-breakpoint-down(xs) {
	.header {
		padding: {
			top: 0;
			bottom: 0; } }
	.header-dropdown_list.column-2 {
		columns: 1; }
	.promo-box > * {
		&:not(:first-child) {
			margin-top: 2.3rem; }
		&:last-child {
			margin-bottom: -21rem; } }
	.promo {
		margin-bottom: 24rem;
		&-block:not(.bg-white) {
			.h1, p {
				text-align: center; } } }
	.info-block_category:before {
		display: none; }
	.article-info {
		text-align: center; }
	.colleges-search {
		margin-bottom: 3rem;
		.form-profession {
			margin-bottom: 1rem; }
		.btn {
			width: 100%; } }
	.header {
		> .container {
			max-width: none;
			padding-right: 0;
			> .row {
				margin: {
					left: 0;
					right: 0; }
				> * {
					padding: {
						left: 0;
						right: 0; } } } }
		&-nav {
			&_list {
				margin: {
					left: 0;
					right: 0; } } } } }
// ≥410px
@include media-breakpoint-down(xxs) {
	.breadcrumbs {
		display: none; }
	.baner-content {
		margin-top: 3rem; }
	.location, .baner-content {
		.baner {
			max-width: 30rem;
			width: 100%!important; } }
	h1, .h1 {
		font-size: 2.6rem; }
	.directions-box {
		margin: {
			left: -1.5rem;
			right: -1.5rem; } }
	.filter-date {
		.form-control {
			width: 100%; } }
	.popup {
		width: 95vw; }
	.colleges-top {
		padding: 1.5rem 1.5rem 1.5rem 4rem; }
	.info-functional .btn {
		width: 100%; }
	.popup {
		padding: 2rem 1.5rem; }
	.college-control {
		overflow: visible;
		border-bottom: 0;
		> .row {
			flex-wrap: wrap;
			> * {
				margin-bottom: 1rem; } }
		&_link {
			border-bottom: .1rem solid #f0f0f0; } }
	.college-functional {
		.btn {
			width: 100%; } }
	.college-info>:not(:first-child) .college-info_text:before {
		display: none; } }

/*==========  Mobile First  ==========*/

// ≤576px
@include media-breakpoint-up(sm);

// ≤768px
@include media-breakpoint-up(md);

// ≤992px
@include media-breakpoint-up(lg) {
	.directions {
		&-box {
			> * {
				@include make-col(1,5); } } }
	.aside {
		width: 27rem;
		flex: 0 0 27rem; } }
// ≤1200px
@include media-breakpoint-up(xl);
